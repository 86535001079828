// @ts-ignore
import KiteIcon from '../../../../../assets/img/product-category-icons/kite.svg?react'
// @ts-ignore
import KiteBarIcon from '../../../../../assets/img/product-category-icons/kite-bar.svg?react'
// @ts-ignore
import WingIcon from '../../../../../assets/img/product-category-icons/wing.svg?react'
// @ts-ignore
import ApparelIcon from '../../../../../assets/img/product-category-icons/apparel.svg?react'
// @ts-ignore
import BackpackIcon from '../../../../../assets/img/product-category-icons/backpack.svg?react'
// @ts-ignore
import BeanieIcon from '../../../../../assets/img/product-category-icons/beanie.svg?react'
// @ts-ignore
import BikiniIcon from '../../../../../assets/img/product-category-icons/bikini.svg?react'
// @ts-ignore
import CampingChairIcon from '../../../../../assets/img/product-category-icons/camping-chair.svg?react'
// @ts-ignore
import FlipflopIcon from '../../../../../assets/img/product-category-icons/flipflop.svg?react'
// @ts-ignore
import GloveIcon from '../../../../../assets/img/product-category-icons/glove.svg?react'
// @ts-ignore
import HikingPoleIcon from '../../../../../assets/img/product-category-icons/hiking-pole.svg?react'
// @ts-ignore
import HikingShoeIcon from '../../../../../assets/img/product-category-icons/hiking-shoe.svg?react'
// @ts-ignore
import JacketIcon from '../../../../../assets/img/product-category-icons/jacket.svg?react'
// @ts-ignore
import MattressIcon from '../../../../../assets/img/product-category-icons/mattress.svg?react'
// @ts-ignore
import PantsIcon from '../../../../../assets/img/product-category-icons/pants.svg?react'
// @ts-ignore
import PulloverIcon from '../../../../../assets/img/product-category-icons/pullover.svg?react'
// @ts-ignore
import SkiingPantsIcon from '../../../../../assets/img/product-category-icons/skiing-pants.svg?react'
// @ts-ignore
import SleepingBagIcon from '../../../../../assets/img/product-category-icons/sleeping-bag.svg?react'
// @ts-ignore
import SneakerIcon from '../../../../../assets/img/product-category-icons/sneaker.svg?react'
// @ts-ignore
import SnowBootIcon from '../../../../../assets/img/product-category-icons/snow-boot.svg?react'
// @ts-ignore
import SportsShoeIcon from '../../../../../assets/img/product-category-icons/sports-shoe.svg?react'
// @ts-ignore
import SupIcon from '../../../../../assets/img/product-category-icons/sup.svg?react'
// @ts-ignore
import TentIcon from '../../../../../assets/img/product-category-icons/tent.svg?react'
// @ts-ignore
import TShirtIcon from '../../../../../assets/img/product-category-icons/t-shirt.svg?react'
// @ts-ignore
import LeggingIcon from '../../../../../assets/img/product-category-icons/legging.svg?react'
// @ts-ignore
import SweaterIcon from '../../../../../assets/img/product-category-icons/sweater.svg?react'
// @ts-ignore
import LongsleeveIcon from '../../../../../assets/img/product-category-icons/long-sleeve.svg?react'
// @ts-ignore
import TanktopIcon from '../../../../../assets/img/product-category-icons/tanktop.svg?react'
// @ts-ignore
import TopIcon from '../../../../../assets/img/product-category-icons/top.svg?react'
// @ts-ignore
import ShortsIcon from '../../../../../assets/img/product-category-icons/shorts.svg?react'
// @ts-ignore
import BikeBagIcon from '../../../../../assets/img/product-category-icons/bike-bag.svg?react'
// @ts-ignore
import BikeRackIcon from '../../../../../assets/img/product-category-icons/bike-rack.svg?react'
// @ts-ignore
import TravelBagIcon from '../../../../../assets/img/product-category-icons/travel-bag.svg?react'
// @ts-ignore
import FoilIcon from '../../../../../assets/img/product-category-icons/foil.svg?react'
// @ts-ignore
import KiteboardIcon from '../../../../../assets/img/product-category-icons/kite-board.svg?react'
// @ts-ignore
import ShoulderBagIcon from '../../../../../assets/img/product-category-icons/freitag/shoulder-bag.svg?react'
// @ts-ignore
import ToteBagIcon from '../../../../../assets/img/product-category-icons/freitag/tote-bag.svg?react'
// @ts-ignore
import FreitagTravelBagIcon from '../../../../../assets/img/product-category-icons/freitag/travel-bag.svg?react'
// @ts-ignore
import MessengerBagIcon from '../../../../../assets/img/product-category-icons/freitag/messenger-bag.svg?react'
// @ts-ignore
import LaptopBagIcon from '../../../../../assets/img/product-category-icons/freitag/laptop-bag.svg?react'
// @ts-ignore
import FreitagBagsIcon from '../../../../../assets/img/product-category-icons/freitag/bags.svg?react'
// @ts-ignore
import FreitagAccessoriesIcon from '../../../../../assets/img/product-category-icons/freitag/accessories.svg?react'

import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'

const allIcons: { [index: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    KiteIcon,
    KiteBarIcon,
    ApparelIcon,
    BackpackIcon,
    BeanieIcon,
    BikiniIcon,
    CampingChairIcon,
    FlipflopIcon,
    GloveIcon,
    HikingPoleIcon,
    HikingShoeIcon,
    JacketIcon,
    MattressIcon,
    PantsIcon,
    PulloverIcon,
    SkiingPantsIcon,
    SleepingBagIcon,
    SneakerIcon,
    SnowBootIcon,
    SportsShoeIcon,
    SupIcon,
    TentIcon,
    TShirtIcon,
    WingIcon,
    LeggingIcon,
    SweaterIcon,
    LongsleeveIcon,
    TanktopIcon,
    TopIcon,
    ShortsIcon,
    BikeBagIcon,
    BikeRackIcon,
    TravelBagIcon,
    KiteboardIcon,
    FoilIcon,
    ShoulderBagIcon,
    ToteBagIcon,
    FreitagTravelBagIcon,
    FreitagBagsIcon,
    MessengerBagIcon,
    LaptopBagIcon,
    FreitagAccessoriesIcon,
}

export default (icon: string) => {
    const Fallback = (props: any) => (
        <img {...props} src={createUploadcareImageSrc(icon, { preview: '200x200' })} />
    )
    const Icon = allIcons[icon] ?? Fallback
    return Icon
}
