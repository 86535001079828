import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 1 as ProductCategoryId,
            name: 'Kiteboarding',
            Icon: 'https://ucarecdn.com/14686e1e-9219-4346-be70-ec3580f00c12/',
            subItems: [
                {
                    id: 201 as ProductTypeId,
                    name: 'Kite',
                    internalName: 'Kite',
                    Icon: 'KiteIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    views: [
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.front',
                            sketchUrl:
                                'https://ucarecdn.com/c30195a4-7994-48e7-afa2-6ccccaebdc7f/-/preview/662x377/',
                            details: [],
                        },
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.bottom',
                            sketchUrl:
                                'https://ucarecdn.com/fcb239b4-3f7d-4693-9705-d1a8d72a7958/-/preview/662x377/',
                            details: [],
                        },
                        {
                            label: 'serviceDesk.shared.pinpointStep.views.side',
                            sketchUrl:
                                'https://ucarecdn.com/ad4d86c4-4088-4e83-a9f3-e7ee99361836/-/preview/662x377/',
                            details: [],
                        },
                    ],
                },
                {
                    id: 202 as ProductTypeId,
                    name: 'Boards',
                    internalName: 'Boards',
                    Icon: 'KiteboardIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    views: [],
                },
                {
                    id: 203 as ProductTypeId,
                    name: 'Bar',
                    internalName: 'Bar',
                    Icon: 'KiteBarIcon',
                    applicableGuides: [],
                    applicableServices: [],
                    views: [],
                },
            ],
        },
        {
            id: 2 as ProductTypeId,
            name: 'Windsurfing',
            internalName: 'Windsurfing',
            Icon: 'https://ucarecdn.com/bb7c7ec2-3d4d-4a63-98a9-b791f9a0919d/',
            applicableGuides: [],
            applicableServices: [],
            views: [],
        },
        {
            id: 3 as ProductTypeId,
            name: 'Wing & Foil',
            internalName: 'Wing & Foil',
            Icon: 'https://ucarecdn.com/5d2a5cc7-4b7c-4b2a-9787-bf718e429d69/',
            applicableGuides: [],
            applicableServices: [],
            views: [],
        },
    ],
}
export default productCategories
