import './main.scss'
import './translations/index'
import React, { PropsWithChildren } from 'react'
import { Provider } from '@rollbar/react'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import AnalyticsContainer from './analytics'
import Hotjar from '@hotjar/browser'
import ReactGA from 'react-ga4'
import CookieBanner from './components/cookie/cookie-banner'
import useEnv from './mixins/use-env'
import rollbarConfig from './rollbar-config'
import RollbarWrapper from './analytics/rollbar-wrapper'
import useAuth from './mixins/use-auth'
import { useTranslation } from 'react-i18next'
import '../mui'

const urlsToExcludeFromCookieConsent = ['/mobile-photo-upload']

const Root = ({ children }: PropsWithChildren) => {
    const { isProd } = useEnv()
    const { isAdmin } = useAuth()
    const shouldInitAnalytics = !isAdmin && isProd
    const { i18n } = useTranslation()
    const { user } = useAuth()
    React.useEffect(() => {
        if (user) {
            if (!i18n.language.includes(user.lang)) {
                i18n.changeLanguage(user.lang === 'de' ? 'de-DE' : 'en-EN')
            }
        }
    }, [])

    const initAnalytics = () => {
        // Hotjar
        const siteId = 3790196
        const hotjarVersion = 6
        Hotjar.init(siteId, hotjarVersion)

        // Initialize React Ga with your tracking ID
        ReactGA.initialize('G-V3K0Q9F7K5')
    }

    const shouldShowCookieBanner = !urlsToExcludeFromCookieConsent.some((url) =>
        window.location.pathname.includes(url)
    )

    return (
        <Provider config={rollbarConfig}>
            <RollbarWrapper>
                <AnalyticsContainer>
                    <ThemeProvider theme={theme}>
                        {children}
                        {shouldShowCookieBanner && (
                            <CookieBanner
                                executeIfCookiesAccepted={
                                    shouldInitAnalytics ? initAnalytics : () => {}
                                }
                            />
                        )}
                    </ThemeProvider>
                </AnalyticsContainer>
            </RollbarWrapper>
        </Provider>
    )
}

export default Root
