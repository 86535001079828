import React from 'react'
import { useQuery, getAllRequests } from 'wasp/client/operations'
import { RouteComponentProps } from 'react-router'
import {
    Page,
    Header,
    WidthContainer,
    Content,
    Footer,
    BrandLogo,
    PoweredBy,
    PoweredByBrakeable,
    PBB,
} from '../service-desk/shared/layout'
import { brkWhitelabel } from '../poc/support/multi-step/data/whitelabel'
import LinktreeEntry from '../service-desk/shared/components/linktree-entry'
import { Box, Drawer, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import categoryIcon from '../service-desk/shared/steps/product-selection/category-icon'
import LocalPolice from '@mui/icons-material/LocalPolice'
import Handyman from '@mui/icons-material/Handyman'
import useUrlQueryState from '../../mixins/use-url-query-state'
import ImageMarker from '../../components/image-marker'

const ServiceDeskRequestsPage = (props: RouteComponentProps<{ companyId: string }>) => {
    const companyId = props.match.params.companyId.split('-').at(-1)!
    const { data, error } = useQuery(getAllRequests, { companyId: companyId })
    const { t } = useTranslation()
    const [selectedRequestType, setSelectedRequestType] = useUrlQueryState(
        'selectedRequestType',
        '0'
    )

    const [openRequestId, setOpenRequestId] = useUrlQueryState('request', undefined)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedRequestType(newValue.toString())
    }

    if (error) return <div>Error</div>
    if (!data) return <div>Loading...</div>

    const selectedRequests = () => {
        if (selectedRequestType === '0') {
            return [...data.repairRequests, ...data.warrantyRequests]
        } else if (selectedRequestType === '1') {
            return data.warrantyRequests
        } else if (selectedRequestType === '2') {
            return data.repairRequests
        }
        return []
    }
    const openRequestIdNumber = parseInt(openRequestId ?? '0')

    const openRequest =
        data.repairRequests.find((r) => r.id === openRequestIdNumber) ||
        data.warrantyRequests.find((r) => r.id === openRequestIdNumber)

    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }} $wide>
                    <a href="/">
                        <BrandLogo src={brkWhitelabel.logo} />
                    </a>
                    <h1>Service Desk Requests</h1>
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer>
                    <Tabs
                        value={parseInt(selectedRequestType ?? '0')}
                        onChange={handleTabChange}
                        aria-label="Different product view tabs"
                        sx={{ mb: 2, width: 'auto', alignSelf: 'center' }}
                    >
                        <Tab
                            label={t('common.requestTypes.all' as any)}
                            id={`product-view-tabs-all`}
                            aria-controls={`productview-tabpanel-all`}
                        />
                        <Tab
                            label={t('common.requestTypes.warranty' as any)}
                            id={`product-view-tabs-warranty`}
                            aria-controls={`productview-tabpanel-warranty`}
                            disabled={data.warrantyRequests.length === 0}
                        />
                        <Tab
                            label={t('common.requestTypes.repair' as any)}
                            id={`product-view-tabs-repair`}
                            aria-controls={`productview-tabpanel-repair`}
                            disabled={data.repairRequests.length === 0}
                        />
                    </Tabs>
                    <Stack component="ul" spacing={1}>
                        {selectedRequests()
                            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                            .map((request) => {
                                const Icon = request.product?.Icon
                                    ? categoryIcon(request.product?.Icon)
                                    : undefined
                                return (
                                    <LinktreeEntry
                                        key={request.type + request.id}
                                        onClick={() => setOpenRequestId(request.id.toString())}
                                    >
                                        <Stack direction="row" spacing={4} width="100%">
                                            {Icon ? (
                                                <Icon style={{ maxWidth: 64, maxHeight: 64 }} />
                                            ) : (
                                                <QuestionMarkIcon
                                                    style={{ width: 64, height: 64 }}
                                                />
                                            )}
                                            <Stack
                                                direction="column"
                                                alignItems="flex-start"
                                                flexGrow={1}
                                            >
                                                <Typography variant="body1" fontStyle="italic">
                                                    "{request.defectDescription}"
                                                </Typography>
                                                <Typography variant="body2">
                                                    {request.createdAtDisplay}
                                                </Typography>
                                            </Stack>
                                            {selectedRequestType === '0' &&
                                                (request.type === 'repair' ? (
                                                    <Handyman />
                                                ) : (
                                                    <LocalPolice />
                                                ))}
                                        </Stack>
                                    </LinktreeEntry>
                                )
                            })}
                    </Stack>
                </WidthContainer>
            </Content>
            <Drawer
                open={!!openRequestId}
                onClose={() => setOpenRequestId(undefined)}
                anchor="right"
            >
                <Box sx={{ maxWidth: '100vw', width: '850px' }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                        mx={2}
                        my={1}
                    >
                        <IconButton onClick={() => setOpenRequestId(undefined)}>
                            <CloseIcon sx={{ color: '#000' }} />
                        </IconButton>
                        <Typography component="h1" variant="h2">
                            {openRequest?.type === 'repair' ? 'Repair' : 'Warranty'}-Request
                        </Typography>
                        <Typography variant="body2">{openRequest?.createdAtDisplay}</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        py={1}
                    >
                        {openRequest?.defectPhotoUrl && (
                            <img
                                src={openRequest?.defectPhotoUrl}
                                alt="Defect"
                                style={{ flexShrink: 1, width: '30%' }}
                            />
                        )}
                        <Stack>
                            <Typography component="h2" variant="h3">
                                Defect Description
                            </Typography>
                            <Typography variant="body1" fontStyle="italic" mb={2}>
                                "{openRequest?.defectDescription}"
                            </Typography>
                            <Typography component="h2" variant="h3">
                                Defect Details
                            </Typography>
                            <Typography variant="body1" fontStyle="italic">
                                ...
                            </Typography>
                        </Stack>
                        {openRequest?.pinpoint && openRequest?.product?.productViewUrl ? (
                            <Box sx={{ flexShrink: 1, width: '30%' }}>
                                <ImageMarker
                                    imageUrl={openRequest?.product?.productViewUrl}
                                    markers={[
                                        { ...openRequest?.pinpoint, id: openRequestIdNumber },
                                    ]}
                                    highlightedSubmissionId={{
                                        id: openRequestIdNumber,
                                        source: 'marker',
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box />
                        )}
                    </Stack>
                </Box>
            </Drawer>
            <Footer>
                <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default ServiceDeskRequestsPage
