import { RepairGuide } from '../repair-guides/types'
import { type RepairService } from '../repair-services'
import { ProductCategoryNode } from '../products/types'
import { type ThemeOptions, type SxProps, alpha, type OutlinedTextFieldProps } from '@mui/material'
import { RepairPlugins } from '../../types/repair/plugins'
import { WarrantyPlugins } from '../../types/warranty/plugins'

export type ItemReferenceIdentification = {
    apiUrl: string
}

export type ServiceDeskContactForm = {
    fields: Field[][]
}

export type Field = {
    key: string
    label: string
    options: Partial<{
        required: boolean
        helperPopup: {
            title: string
            text?: string
            imgUrl?: string
        }
    }>
    fieldProps?: Partial<
        {
            fieldControlSx: SxProps
        } & Omit<OutlinedTextFieldProps, 'variant'>
    >
}

export enum WhitelabelStatus {
    InternalOnly,
    ExternalDemoAccess,
    Live,
}

export type Configuration = {
    repair?: {
        itemReferenceIdentification?: ItemReferenceIdentification
        contactForm: ServiceDeskContactForm
        displayRepairOptions?: boolean
        activateCostEstimation: boolean
        includeDiyGuides: boolean
        outro: {
            lead: string
            links: {
                href: string
                label: string
            }[]
        }
        storeFinderIframeUrl?: string
        backendPlugins: RepairPlugins[]
    }
    warranty?: {
        contactForm: ServiceDeskContactForm
        proofOfPurchaseFields: Field[][]
        activateWarrantyEstimation: boolean
        outro: {
            lead: string
            links: {
                href: string
                label: string
            }[]
        }
        backendPlugins: WarrantyPlugins[]
    }
    ufixit?: {
        outro: {
            assistanceIframeUrl?: string
        }
    }
}

export type Whitelabel = {
    id: string
    status: WhitelabelStatus
    name: string
    theme: Partial<ThemeOptions>
    logo: string
    links?: Partial<{
        storeFinder: string
    }>
    repairGuides: RepairGuide[]
    repairServices: RepairService[]
    productCategoryTree: ProductCategoryNode
    hideFakeFeatures: boolean
    config: Configuration
}
