import vidarProductCategoryTree from '../products/vidar-products'
import { WhitelabelStatus, type Whitelabel } from './types'
import { alpha } from '@mui/material'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const vidarWhitelabel: Whitelabel = {
    id: '6pzljy',
    status: WhitelabelStatus.Live,
    name: 'Vidar',
    theme: {
        palette: {
            primary: {
                main: '#62D3A7',
                contrastText: '#000000',
            },
            secondary: {
                main: '#F2C650',
                contrastText: '#000000',
            },
            getCrosshairColors: () => ({ stroke: '#62D3A7', fill: '#000000' }),
            getIllustrationColors: () => ({
                primary: '#F3C650',
                accent: '#62D3A7',
            }),
            getProgressbarColor: () => '#62D3A7',
            getTextColor: () => '#000000',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        color: '#000000',
                        borderColor: alpha('#62D3A7', 0.8),
                    },
                },
            },
        },
    },
    logo: 'https://ucarecdn.com/4f2f2a46-b81f-42d0-adc8-6c66628aee2e/',
    repairGuides: [],
    repairServices: [],
    productCategoryTree: vidarProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            options: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: false,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.vidar-sport.de',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'rouven@vidar-sport.de',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default vidarWhitelabel
