import React from 'react'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import { DialogContent, Dialog, IconButton, Stack, styled, DialogTitle } from '@mui/material'
import T from '../../../../../components/typography/t'
import { WidthContainer } from '../../../shared/layout'
import LinktreeEntry from '../../../shared/components/linktree-entry'
import { type RepairService } from '../../../../../../shared/data/repair-services'
import useDialog from '../../../../../mixins/use-dialog'
import CloseIcon from '@mui/icons-material/Close'

const Linktree: React.FC<{
    onDiyClick: () => void
    onServicesClick: () => void
    repairGuideCount: number
    selectedServices: RepairService[]
    storeFinderIframeUrl?: string
}> = ({
    onDiyClick,
    onServicesClick,
    repairGuideCount,
    selectedServices,
    storeFinderIframeUrl,
}) => {
    const { t, i18n } = useTranslation()
    const { isOpen, open, close } = useDialog()

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.solutionStep.linktree.title" mb={4} />
            <WidthContainer>
                <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <LinktreeEntry onClick={onDiyClick} disabled={repairGuideCount === 0}>
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diyTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diySubtitle"
                        />
                    </LinktreeEntry>
                    <LinktreeEntry onClick={onServicesClick}>
                        {selectedServices.length > 0 ? (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesSubtitle"
                                />
                            </>
                        ) : (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormSubtitle"
                                />
                            </>
                        )}
                    </LinktreeEntry>
                    {storeFinderIframeUrl && (
                        <LinktreeEntry onClick={open}>
                            <T
                                variant="h3"
                                mb={1}
                                i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                            />
                            <T
                                variant="body1"
                                i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderSubtitle"
                            />
                        </LinktreeEntry>
                    )}
                </Stack>
            </WidthContainer>
            <Dialog onClose={close} open={isOpen} fullScreen>
                <DialogTitle>
                    <T
                        mb={1}
                        variant="h3"
                        i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                    />
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <iframe
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            minHeight: '620px',
                        }}
                        src={`${storeFinderIframeUrl}${i18n.language.slice(0, 2)}`}
                    ></iframe>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Linktree
