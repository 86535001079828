import ortliebProductCategoryTree from '../products/ortlieb-products'
import { WhitelabelStatus, type Whitelabel } from './types'
import ortliebRepairGuides from '../repair-guides/ortlieb-repair-guide-videos'
import ortliebB2bRepairServices from '../repair-services/fake-ortlieb-b2b-support-services'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const ortliebWhitelabel: Whitelabel = {
    id: '1c3fyk',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Ortlieb (B2B)',
    theme: {
        palette: {
            primary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#EE9000', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#EE9000', accent: '#23201F' }),
            getProgressbarColor: () => '#EE9000',
            getTextColor: () => '#23201F',
        },
    },
    logo: 'https://ucarecdn.com/2a1a8f22-1111-4932-948d-00c7f8672ea3/',
    repairGuides: ortliebRepairGuides,
    repairServices: ortliebB2bRepairServices,
    productCategoryTree: ortliebProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            options: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            options: { required: true },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'ram@ortlieb.de',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            options: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            options: { required: true },
                        },
                    ],
                ],
            },
            proofOfPurchaseFields: [],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.ortlieb.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'ram@ortlieb.de',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default ortliebWhitelabel
