import React from 'react'
import { UploadClient } from '@uploadcare/upload-client'
import { FormValues, WarrantyMultiStepFormContext, WhitelabelWithWarrantyConfig } from './context'
import { ProductTypeId } from '../../../../shared/data/products/types'
import { findProductTypeById } from '../../../../shared/data/products/helpers'
import { MultiStepLayout } from '../shared/layout'
import { useTranslation } from 'react-i18next'
import ProductSelectionStep from './steps/product-selection'
import PinpointStep from './steps/pinpoint'
import DefectPhotoStep from './steps/defect-photo'
import PurchaseConfirmationStep from './steps/purchase-confirmation'
import DescriptionStep from './steps/description'
import SolutionStep from './steps/solution/index'
import OutroStep from './steps/outro/index'
import useSlider, { type Slide } from '../shared/helper/use-slider'
import useMultiStepForm from '../shared/helper/use-multi-step-form'
import useMultiStepError from '../shared/helper/use-multi-step-error'
import { useAnalytics } from 'use-analytics'
import { WarrantyRequestStepChangeEvent } from '../../../../shared/analytics'
import useUrlQuery from '../../../mixins/use-url-query'

const WarrantyMultiStep = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: WhitelabelWithWarrantyConfig
}) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const slideDefinition: Slide[] = [
        { name: 'ProductSelectionStep', Component: () => <ProductSelectionStep />, skip: false },
        { name: 'PinpointStep', Component: () => <PinpointStep />, skip: false },
        { name: 'DefectPhotoStep', Component: () => <DefectPhotoStep />, skip: false },
        { name: 'DescriptionStep', Component: () => <DescriptionStep />, skip: false },
        {
            name: 'PurchaseConfirmationStep',
            Component: () => <PurchaseConfirmationStep />,
            skip: false,
        },
        { name: 'SolutionStep', Component: () => <SolutionStep />, skip: false },
        { name: 'OutroStep', Component: () => <OutroStep />, skip: false },
    ]

    const analytics = useAnalytics()
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const {
        slides,
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
    } = useSlider(slideDefinition, 0)

    const { errors, pushError, popError } = useMultiStepError()
    const formDefaultValues: FormValues = {
        selectedProduct: undefined,
        selectedProductView: 0,
        description: undefined,
        defectPhotoUrl: undefined,
        receiptPhotoUrl: undefined,
        proofOfPurchaseForm: undefined,
        pinpoint: undefined,
        selectedComponent: undefined,
        contactFormValues: {},
    }
    const { form, resetForm, submitForm, formValues } = useMultiStepForm<FormValues>(
        formDefaultValues,
        onSubmit
    )

    const productCategoryTree = whitelabel.productCategoryTree

    const selectedProductNode = findProductTypeById(
        (form.getValues('selectedProduct') ?? (-1 as any)) as ProductTypeId,
        productCategoryTree
    )

    React.useEffect(() => {
        analytics.track(WarrantyRequestStepChangeEvent, {
            brandId: whitelabel.id,
            slide: slides[slideIndex].name,
        })
    }, [slideIndex])

    return (
        <WarrantyMultiStepFormContext.Provider
            value={{
                ctx: {
                    whitelabel,
                    productCategoryTree: productCategoryTree,
                    isIframe,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    setSlideSkip,
                },
                form: {
                    setValue: form.setValue,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <MultiStepLayout logo={whitelabel.logo} progress={currentStep / (maxSteps - 1)}>
                {slides[slideIndex].Component()}
            </MultiStepLayout>
        </WarrantyMultiStepFormContext.Provider>
    )
}

export default WarrantyMultiStep
