import Origin from './inspector/origin'
import CompanyId from './types/company/id'
import ProductId from './types/product/id'
import QuestionaireUuid from './types/questionaire/uuid'
import SubmissionId from './types/submission/id'

export const createRouteBuilder = (basePath: string) => ({
    base: () => basePath,
    companyOnboarding: (token: string) => `${basePath}/company-onboarding?token=${token}`,
    companyIntroduction: () => `${basePath}/company-introduction`,
    companyDashboard: () => `${basePath}/`,
    inspectorApp: (companyId?: CompanyId) => `${basePath}/inspector-app/${companyId ?? ''}`,
    reportApp: (companyId?: CompanyId) => `${basePath}/report-app/${companyId ?? ''}`,
    userApp: (companyId?: CompanyId) => `${basePath}/user-app/${companyId ?? ''}`,
    supportApp: (companyId?: CompanyId) => `${basePath}/support-app/${companyId ?? ''}`,
    companySettings: (companyId?: CompanyId) => `${basePath}/company-settings/${companyId ?? ''}`,
    companyProductOverview: (companyUuid: string) => `${basePath}/company/${companyUuid}/products`,
    companySupportOverview: (companyUuid: string) => `${basePath}/company/${companyUuid}/support`,
    productDashboardLink: (
        productId: ProductId,
        companyId?: CompanyId,
        submissionId?: SubmissionId
    ) =>
        `${basePath}/inspector-app/${companyId ? `${companyId}/` : ''}product/${productId}${
            submissionId ? `?submissionId=${submissionId}` : ''
        }`,
    productInspectorUrl: (
        questionaireUuid: QuestionaireUuid,
        { source, origin }: { source?: string; origin?: Origin } = {}
    ) => {
        const searchParams = new URLSearchParams()
        if (source) searchParams.append('source', source)
        if (origin) searchParams.append('origin', origin)
        const queryString = searchParams.toString() ? `?${searchParams.toString()}` : ''
        return `${basePath}/inspector/${questionaireUuid}${queryString}`
    },
    supportInspectorUrl: (
        questionaireUuid: QuestionaireUuid,
        { source, origin }: { source?: string; origin?: Origin } = {}
    ) => {
        const searchParams = new URLSearchParams()
        if (source) searchParams.append('source', source)
        if (origin) searchParams.append('origin', origin)
        const queryString = searchParams.toString() ? `?${searchParams.toString()}` : ''
        return `${basePath}/support/${questionaireUuid}${queryString}`
    },
    companyFallbackLogo: () => `${basePath}/company-fallback-logo.png`,
    companyRedirectUrl: (url: string) => (url.startsWith('http') ? url : `https://${url}`),
    productExampleImage: () => `${basePath}/product-example-image.png`,

    serviceDesk: (companyId: string, companyName?: string, module?: string) =>
        `${basePath}/service-desk/${companyName ? `${companyName}-` : ''}${companyId}${
            module ? `/${module}` : ''
        }`,
    explorer: (companyId: string, companyName?: string) =>
        `${basePath}/explorer/${companyName ? `${companyName}-` : ''}${companyId}`,
    analysator: (companyId: string, companyName?: string) =>
        `${basePath}/analysator/${companyName ? `${companyName}-` : ''}${companyId}`,
    iframeDemo: (companyId: string, companyName?: string) =>
        `//brakeable.com/demos/${companyName ? `${companyName}-` : ''}${companyId}`,
    serviceDeskMobilePhotoUpload: (companyId: string, sessionId: string, companyName?: string) =>
        `${basePath}/service-desk/${
            companyName ? `${companyName}-` : ''
        }${companyId}/mobile-photo-upload?i=${sessionId}`,
})
