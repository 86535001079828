import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Button } from '@mui/material'
import T from '../../../../../components/typography/t'
import { UseFormReturn } from 'react-hook-form'
import InputField from '../../../../../components/form/input-field'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'

export type FormData = {
    [index: string]: string | number | boolean
}

const NoSuggestionsContactForm: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    onPreferDiySolution?: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
}> = ({ onSubmit, onAddMoreDefectsClick, onPreferDiySolution, contactForm, useForm }) => {
    const [t] = useTranslation()
    const { register, handleSubmit } = useForm
    return (
        <form
            data-testid="contact-form"
            style={{ width: '100%' }}
            onSubmit={(e) => {
                handleSubmit(onSubmit)(e)
                e.preventDefault()
            }}
            method="post"
        >
            {contactForm.fields.map((cells, index) => (
                <Stack
                    data-testid={`form-row-${index}`}
                    direction="row"
                    mb={index === contactForm.fields.length - 1 ? 0 : 1}
                    gap={2}
                    sx={{ width: '100%' }}
                    key={index}
                >
                    {cells.map((cell) => (
                        <InputField
                            data-testid={`input-${cell.key}`}
                            key={cell.key}
                            id={cell.key}
                            fullWidth
                            {...register(cell.key as any, cell.options)}
                            {...(cell.fieldProps as any)}
                            required={cell.options.required}
                            label={t(cell.label as any)}
                        />
                    ))}
                </Stack>
            ))}

            <Stack
                direction={{ sm: 'column', md: 'row' }}
                gap={2}
                justifyContent="space-between"
                sx={{ width: '100%', flexWrap: 'wrap' }}
                mt={4}
            >
                {/* <Button data-testid="add-more-defects" onClick={onAddMoreDefectsClick}>
                    <T i18nKey="serviceDesk.repair.contactStep.form.addMoreDefects" />
                </Button> */}
                {!!onPreferDiySolution && (
                    <Button data-testid="prefer-diy-button" onClick={onPreferDiySolution}>
                        <T i18nKey="serviceDesk.repair.costEstimationStep.diyAction" />
                    </Button>
                )}
                <div />
                <Button data-testid="submit-button" type="submit" variant="contained">
                    <T i18nKey="serviceDesk.repair.contactStep.form.submit" />
                </Button>
            </Stack>
        </form>
    )
}
export default NoSuggestionsContactForm
