import React from 'react'
import { RouteComponentProps } from 'react-router'
import whitelabels from '../../../../shared/data/whitelabel'
import RepairMultiStep from './repair-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import { submitRepairRequest } from 'wasp/client/operations'
import { FormValues, WhitelabelWithRepairConfig } from './context'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { RepairRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import intersportWhitelabel from '../../../../shared/data/whitelabel/intersport-whitelabel'

export const IntersportRepairModule = () => {
    return <RepairModule whitelabel={intersportWhitelabel as WhitelabelWithRepairConfig} />
}

const RepairModule = ({ whitelabel }: { whitelabel: WhitelabelWithRepairConfig }) => {
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    React.useEffect(() => {
        analytics.track(RepairRequestPageViewEvent, { isMobile, brandId: whitelabel.id, utmData })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        await submitRepairRequest({
            companyId: whitelabel.id,
            defectPhotoUrl: formValues.uploadedPhotoUrl!,
            pinpoint: formValues.pinpoint,
            description: formValues.description!,
            product: formValues.selectedProduct,
            productView: formValues.selectedProductView,
            contactForm: formValues.contactFormValues,
            utmData: utmData,
            anonymousUserId: analytics.user().anonymousId,
            aiSuggestedRepairServices: formValues.aiSelectedServices,
            aiSuggestedRepairGuides: formValues.aiSelectedRepairGuides,
            userAcceptedRepairServices: formValues.acceptedServices,
        })
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <RepairMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithRepairConfig}
                onSubmit={handleSubmit}
            />
        </ThemeProvider>
    )
}

export default (props: RouteComponentProps<{ company: string }>) => {
    const company = props.match.params.company
    const urlParams = company.split('-')
    const companyId = urlParams[urlParams.length - 1]

    const whitelabel = whitelabels.find((w) => w.id === companyId)

    if (!whitelabel || !whitelabel.config.repair) {
        window.location.href = '//www.brakeable.com'
        return null
    }

    return <RepairModule whitelabel={whitelabel as WhitelabelWithRepairConfig} />
}
