import whitelabels from '../../../../shared/data/whitelabel'
import { Whitelabel } from '../../../../shared/data/whitelabel/types'
import { RouteComponentProps } from 'react-router'
import { Stack, Chip, useTheme, Tabs, Tab, styled, Card, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../shared/layout'
import { useQuery, getServiceDeskAnalytics } from 'wasp/client/operations'
import routeBuilder from '../../../routes'
import { BarChart } from '@mui/x-charts/BarChart'
import useUrlQueryState from '../../../mixins/use-url-query-state'
import T from '../../../components/typography/t'

const KeyfactCard = styled(Card)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius}px;
    box-shadow: ${theme.shadows[1]};
    background-color: ${theme.palette.background.paper};
    
    h2 {
        font-size: 3rem;
        font-weight: 600;
        color: ${theme.palette.primary.main};
    }
`
)

const AnalysatorPage = (props: RouteComponentProps<{ companyId: string }>) => {
    const companyId = props.match.params.companyId.split('-').at(-1)!
    const whitelabel: Whitelabel | undefined = whitelabels.find((w) => w.id === companyId)
    const theme = useTheme()
    const { t } = useTranslation()
    const [selectedRequestType, setSelectedRequestType] = useUrlQueryState(
        'selectedRequestType',
        '0'
    )
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedRequestType(newValue.toString())
    }

    if (!whitelabel) {
        window.location.href = routeBuilder.base()
        return null
    }

    const { data: analytics, error } = useQuery(getServiceDeskAnalytics, { companyId })

    if (error) {
        return 'error'
    }
    if (!analytics) {
        return 'loading'
    }

    const timerange = 14

    const pageViewEvents =
        selectedRequestType === '0'
            ? analytics.repair.pageViewEvents.concat(analytics.warranty.pageViewEvents)
            : selectedRequestType === '1'
            ? analytics.warranty.pageViewEvents
            : analytics.repair.pageViewEvents
    const requestSubmissions =
        selectedRequestType === '0'
            ? analytics.repair.requestSubmissions.concat(analytics.warranty.requestSubmissions)
            : selectedRequestType === '1'
            ? analytics.warranty.requestSubmissions
            : analytics.repair.requestSubmissions

    const slideCounts =
        selectedRequestType === '0'
            ? // merge both slide counts by adding them up
              Object.entries(analytics.repair.slideCounts).reduce((acc, [slide, count]) => {
                  acc[slide] = (acc[slide] || 0) + count
                  return acc
              }, {} as Record<string, number>)
            : selectedRequestType === '1'
            ? analytics.warranty.slideCounts
            : analytics.repair.slideCounts

    const firstTimeVisitPerUser = pageViewEvents
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
        .reduce((acc, event) => {
            if (!acc[event.anonymousUserId]) {
                acc[event.anonymousUserId] = event.timestamp
            }
            return acc
        }, {} as Record<string, Date>)

    const getData = (
        pageViewEvents: { timestamp: Date; anonymousUserId: string }[],
        requestSubmissions: { timestamp: Date }[]
    ) => {
        return Array.from({ length: timerange }, (_, i) => {
            const date = new Date()
            date.setDate(date.getDate() - (timerange - (i + 1)))
            return date.toDateString()
        }).reduce(
            (acc, date) => {
                const views = pageViewEvents.filter(
                    (event) => event.timestamp.toDateString() === date
                )
                acc[date] = {
                    views: views.length,
                    uniqueVisitors: new Set(views.map((event) => event.anonymousUserId)).size,
                    submissions: requestSubmissions.filter(
                        (event) => event.timestamp.toDateString() === date
                    ).length,
                    firstTimeVisitors: Object.values(firstTimeVisitPerUser).filter(
                        (timestamp) => timestamp.toDateString() === date
                    ).length,
                }
                return acc
            },
            {} as Record<
                string,
                {
                    views: number
                    uniqueVisitors: number
                    submissions: number
                    firstTimeVisitors: number
                }
            >
        )
    }
    const data = getData(pageViewEvents, requestSubmissions)

    const dataset = Object.entries(data).map(([date, data]) => ({
        date,
        views: data.views,
        uniqueVisitors: data.uniqueVisitors,
        submissions: data.submissions,
        firstTimeVisitors: data.firstTimeVisitors,
    }))
    const totalViews = pageViewEvents.length
    const uniqueVisitors = new Set(pageViewEvents.map((event) => event.anonymousUserId)).size
    const totalSubmissions = requestSubmissions.length
    const maxViewCount = Math.max(
        ...Object.values(
            getData(analytics.repair.pageViewEvents.concat(analytics.warranty.pageViewEvents), [])
        ).map((data) => data.views)
    )

    const slideCountsDataset = Object.entries(slideCounts).map(([slide, count]) => ({
        slide,
        count,
    }))
    return (
        <Page>
            <Header>
                <WidthContainer $wide sx={{ justifyContent: 'space-between' }}>
                    <a href="/">
                        <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                    </a>
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer $wide>
                    <Tabs
                        value={parseInt(selectedRequestType ?? '0')}
                        onChange={handleTabChange}
                        aria-label="Different product view tabs"
                        sx={{ mb: 4, width: 'auto', alignSelf: 'center' }}
                    >
                        <Tab
                            label={t('common.requestTypes.all' as any)}
                            id={`product-view-tabs-all`}
                            aria-controls={`productview-tabpanel-all`}
                        />
                        <Tab
                            label={t('common.requestTypes.warranty' as any)}
                            id={`product-view-tabs-warranty`}
                            aria-controls={`productview-tabpanel-warranty`}
                        />
                        <Tab
                            label={t('common.requestTypes.repair' as any)}
                            id={`product-view-tabs-repair`}
                            aria-controls={`productview-tabpanel-repair`}
                        />
                    </Tabs>
                    <Stack
                        direction="row"
                        mb={4}
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <KeyfactCard>
                            <Typography variant="h2">{totalViews}</Typography>
                            <T i18nKey="analysator.keyfacts.pageViews" variant="body1" />
                        </KeyfactCard>
                        <KeyfactCard>
                            <Typography variant="h2">{uniqueVisitors}</Typography>
                            <T i18nKey="analysator.keyfacts.uniqueVisitors" variant="body1" />
                        </KeyfactCard>
                        <KeyfactCard>
                            <Typography variant="h2">{totalSubmissions}</Typography>
                            <T i18nKey="analysator.keyfacts.submissions" variant="body1" />
                        </KeyfactCard>
                    </Stack>

                    <Box sx={{ height: 400 }}>
                        <BarChart
                            dataset={dataset}
                            series={[
                                { dataKey: 'views', label: 'Page Views' },
                                { dataKey: 'uniqueVisitors', label: 'Unique Visitors' },
                                { dataKey: 'submissions', label: 'Submissions' },
                                { dataKey: 'firstTimeVisitors', label: 'First Time Visitors' },
                            ]}
                            xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                            yAxis={[{ scaleType: 'linear', max: maxViewCount }]}
                            colors={[
                                theme.palette.info.light,
                                theme.palette.primary.light,
                                theme.palette.warning.light,
                                theme.palette.success.light,
                            ]}
                            barLabel="value"
                            sx={{
                                '& .MuiBarLabel-root': { fill: 'black' },
                            }}
                        />
                    </Box>
                    <Typography variant="h6">Slide Counts</Typography>
                    <Box sx={{ height: 200 }}>
                        <BarChart
                            dataset={slideCountsDataset}
                            series={[{ dataKey: 'count', label: 'Slide Counts' }]}
                            xAxis={[{ scaleType: 'band', dataKey: 'slide' }]}
                            yAxis={[
                                {
                                    scaleType: 'linear',
                                    max: Math.max(...slideCountsDataset.map((data) => data.count)),
                                },
                            ]}
                            colors={[theme.palette.info.main]}
                        />
                    </Box>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default AnalysatorPage
