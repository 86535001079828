export const RepairRequestPageViewEvent = 'RepairRequest_Pageview'
export const RepairRequestStepChangeEvent = 'RepairRequest_StepChange'
export const RepairRequestAiSuggestedServicesEvent = 'RepairRequest_AiSuggestedServices'
export const RepairRequestAiSuggestedGuidesEvent = 'RepairRequest_AiSuggestedGuides'
export const WarrantyRequestPageViewEvent = 'WarrantyRequest_Pageview'
export const WarrantyRequestStepChangeEvent = 'WarrantyRequest_StepChange'

export const InspectorPageViewMobileEvent = 'Inspector_Pageview:Mobile'
export const InspectorPageViewDesktopEvent = 'Inspector_Pageview:Desktop'
export const UnknownUserId = 'unknown'
export const ClientDevice = {
    Mobile: 'mobile',
    Desktop: 'desktop',
}
