import React from 'react'
import {
    getRepairGuideSuggestions as getRepairGuideSuggestionsServerAction,
    getRepairServiceAssignments as getRepairServiceAssignmentsServerAction,
} from 'wasp/client/operations'
import { UploadClient } from '@uploadcare/upload-client'
import {
    FormValues,
    RepairMultiStepFormContext,
    RepairType,
    WhitelabelWithRepairConfig,
} from './context'
import {
    RepairRequestStepChangeEvent,
    RepairRequestAiSuggestedGuidesEvent,
    RepairRequestAiSuggestedServicesEvent,
} from '../../../../shared/analytics'
import { ProductTypeId } from '../../../../shared/data/products/types'
import { findProductTypeById } from '../../../../shared/data/products/helpers'
import { MultiStepLayout } from '../shared/layout'
import { useTranslation } from 'react-i18next'
import ProductSelectionStep from './steps/product-selection'
import PinpointStep from './steps/pinpoint'
import PhotoStep from './steps/photo'
import DescriptionStep from './steps/description'
import SolutionStep from './steps/solution/index'
import OutroStep from './steps/outro/index'
import RepairTypeStep from './steps/repair-type'
import useSlider, { type Slide } from '../shared/helper/use-slider'
import useMultiStepForm from '../shared/helper/use-multi-step-form'
import useMultiStepError from '../shared/helper/use-multi-step-error'
import { useAnalytics } from 'use-analytics'
import useUrlQuery from '../../../mixins/use-url-query'

const RepairMultiStep = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: WhitelabelWithRepairConfig
}) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const slideDefinition: Slide[] = [
        { name: 'ProductSelectionStep', Component: () => <ProductSelectionStep />, skip: false },
        { name: 'PinpointStep', Component: () => <PinpointStep />, skip: false },
        { name: 'PhotoStep', Component: () => <PhotoStep />, skip: false },
        { name: 'DescriptionStep', Component: () => <DescriptionStep />, skip: false },
        {
            name: 'RepairTypeStep',
            Component: () => <RepairTypeStep />,
            skip: !whitelabel.config.repair.displayRepairOptions,
        },
        { name: 'SolutionStep', Component: () => <SolutionStep />, skip: false },
        { name: 'OutroStep', Component: () => <OutroStep />, skip: false },
    ]

    const analytics = useAnalytics()
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const {
        slides,
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
    } = useSlider(slideDefinition, 0)

    const { errors, pushError, popError } = useMultiStepError()
    const formDefaultValues: FormValues = {
        selectedProduct: undefined,
        selectedProductView: 0,
        repairType: RepairType.INVISIBLE,
        aiSelectedServices: [],
        aiSelectedRepairGuides: [],
        acceptedServices: [],
        description: undefined,
        uploadedPhotoUrl: undefined,
        pinpoint: undefined,
        selectedComponent: undefined,
        contactFormValues: {},
    }
    const { form, resetForm, submitForm, formValues } = useMultiStepForm<FormValues>(
        formDefaultValues,
        onSubmit
    )

    const productCategoryTree = whitelabel.productCategoryTree

    const selectedProductNode = findProductTypeById(
        (form.getValues('selectedProduct') ?? (-1 as any)) as ProductTypeId,
        productCategoryTree
    )

    const getRepairServiceAssignments = async () => {
        if (selectedProductNode) {
            const selectedRepairGuideIds = await getRepairServiceAssignmentsServerAction({
                productCategory: t(selectedProductNode.name as any),
                problemDescription: form.getValues('description')!,
                selectedProductComponent: !!form.getValues('selectedComponent')
                    ? {
                          name: form.getValues('selectedComponent')!,
                          potentialFeatures: [],
                      }
                    : undefined,
                availableServices: selectedProductNode.applicableServices,
                brandId: whitelabel.id,
            })
            form.setValue('aiSelectedServices', selectedRepairGuideIds)
            form.setValue('acceptedServices', selectedRepairGuideIds)
            if (selectedRepairGuideIds.length > 0) {
                analytics.track(RepairRequestAiSuggestedServicesEvent, {
                    brandId: whitelabel.id,
                    productCategory: t(selectedProductNode.name as any),
                    productComponent: form.getValues('selectedComponent'),
                    defectDescription: form.getValues('description'),
                    serviceIds: selectedRepairGuideIds,
                })
            }
        }
    }

    const getRepairGuideSuggestions = async () => {
        if (selectedProductNode) {
            const selectedRepairGuideIds = await getRepairGuideSuggestionsServerAction({
                productCategory: t(selectedProductNode.name as any),
                problemDescription: form.getValues('description')!,
                selectedProductComponent: !!form.getValues('selectedComponent')
                    ? {
                          name: form.getValues('selectedComponent')!,
                          potentialFeatures: [],
                      }
                    : undefined,
                availableGuides: selectedProductNode.applicableGuides,
                brandId: whitelabel.id,
            })
            form.setValue('aiSelectedRepairGuides', selectedRepairGuideIds)
            if (selectedRepairGuideIds.length > 0) {
                analytics.track(RepairRequestAiSuggestedGuidesEvent, {
                    brandId: whitelabel.id,
                    productCategory: t(selectedProductNode.name as any),
                    productComponent: form.getValues('selectedComponent'),
                    defectDescription: form.getValues('description'),
                    guideIds: selectedRepairGuideIds,
                })
            }
        }
    }

    React.useEffect(() => {
        analytics.track(RepairRequestStepChangeEvent, {
            brandId: whitelabel.id,
            slide: slides[slideIndex].name,
        })
    }, [slideIndex])

    return (
        <RepairMultiStepFormContext.Provider
            value={{
                ctx: {
                    whitelabel,
                    repairGuides: whitelabel.repairGuides,
                    repairServices: whitelabel.repairServices,
                    productCategoryTree: productCategoryTree,
                    getRepairGuideSuggestions,
                    getRepairServiceAssignments,
                    isIframe,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    setSlideSkip,
                },
                form: {
                    setValue: form.setValue,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <MultiStepLayout logo={whitelabel.logo} progress={currentStep / (maxSteps - 1)}>
                {slides[slideIndex].Component()}
            </MultiStepLayout>
        </RepairMultiStepFormContext.Provider>
    )
}

export default RepairMultiStep
