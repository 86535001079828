import {
    submitQuestionaireSubmission,
    useQuery,
    getQuestionaireViewModel,
} from 'wasp/client/operations'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import InspectorMultiStepForm from './inspector-multi-step-form'
import PostInspectionStrategy from '../../../../shared/company/post-inspection-strategy'
import { FormValues } from './types'
import SplashScreen from './splash-screen'
import DesktopFallback from './desktop-fallback'
import routeBuilder from '../../../routes'
import { useIsDesktop } from '../../../mixins/media-query'
import Origin from '../../../../shared/inspector/origin'
import uploadcareClient from '../../../mixins/uploadcare-client'
import parseQueryString from '../../../mixins/parse-query-string'

const QuestionairePage = (props: RouteComponentProps<{ questionaireUuid: string }>) => {
    const [hasFormAlreadySubmitted, sethasFormAlreadySubmitted] = React.useState(false)
    const [splashScreenAnimationDone, setSplashScreenAnimationDone] = React.useState(false)
    const utmData = parseQueryString()
    const questionaireUuid = props.match.params.questionaireUuid
    const isDektop = useIsDesktop()
    const searchParams = new URLSearchParams(window.location.search)
    const origin = searchParams.get('origin') ?? Origin.Product
    const source = searchParams.get('source') ?? 'other'

    const { data, isFetching, error } = useQuery(getQuestionaireViewModel, {
        questionaireUuid: questionaireUuid,
    })

    if (isFetching || !splashScreenAnimationDone) {
        return <SplashScreen onAnimationDone={() => setSplashScreenAnimationDone(true)} />
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    const questionaire = data!

    const onSubmit = async (formValues: FormValues, anonymousUserId: string) => {
        if (hasFormAlreadySubmitted) {
            return
        }
        sethasFormAlreadySubmitted(true)
        await submitQuestionaireSubmission({
            questionaireId: questionaire.questionaireId,
            submission: {
                email: formValues.inspectionEmail,
                anonymousUserId: anonymousUserId,
                inspections: [
                    {
                        imageUrl: formValues.inspectionImageUrl!,
                        positionX: formValues.inspectionImagePinpoint!.x,
                        positionY: formValues.inspectionImagePinpoint!.y,
                        description: formValues.inspectionDescription!,
                        contextDescription: formValues.inspectionContextDescription!,
                        contactRequested: formValues.contactRequested,
                        utmData,
                    },
                ],
            },
        })
        return
    }

    const postInspectionAction = () => {
        switch (data?.config.postInspectionStrategy) {
            case PostInspectionStrategy.Redirect: {
                location.replace(routeBuilder.companyRedirectUrl(data.companyWebsiteUrl))
                return
            }
            case PostInspectionStrategy.Restart: {
                if (origin === Origin.Company) {
                    location.replace(routeBuilder.companyProductOverview(data.companyUuid))
                } else {
                    location.replace(location.href)
                }

                return
            }
        }
    }
    /* 
    if (isDektop) {
        return <DesktopFallback questionaireUuid={questionaireUuid} source={source} />
    }
 */
    return (
        <InspectorMultiStepForm
            productName={questionaire.productName}
            productImageUrl={questionaire.productImageUrl}
            companyName={questionaire.companyName}
            companyLogo={questionaire.companyLogo}
            postInspectionAction={postInspectionAction}
            allowHappySubmissions={questionaire.config.allowHappySubmissions}
            allowGalleryUpload={questionaire.config.allowGalleryUpload}
            allowRemindMeLater={questionaire.config.allowRemindMeLater}
            isEmailMandatory={questionaire.config.isEmailMandatory}
            allowContactRequests={questionaire.config.allowContactRequests}
            questionaireId={questionaire.questionaireId}
            questionaireUuid={questionaire.questionaireUuid}
            source={source}
            onSubmit={onSubmit}
            uploadCareClient={uploadcareClient}
        />
    )
}

export default QuestionairePage
