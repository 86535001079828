import einsteinProductCategoryTree from '../products/einstein-products'
import { WhitelabelStatus, type Whitelabel } from './types'
import vaudeRepairGuides from '../repair-guides'
import einsteinRepairServices from '../repair-services/einstein-repair-services'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/

const einsteinWhitelabel: Whitelabel = {
    id: '2num3c',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Einstein',
    theme: {
        palette: {
            primary: {
                main: '#09C',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#99CC01',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#09C', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#09C', accent: '#99CC01' }),
            getProgressbarColor: () => '#09C',
            getTextColor: () => '#000',
        },
    },
    logo: 'https://ucarecdn.com/c94d154b-53d3-45fd-9262-357f4087432b/',
    repairGuides: vaudeRepairGuides,
    repairServices: einsteinRepairServices,
    productCategoryTree: einsteinProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            options: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            options: { required: true },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'k.steiner@einstein-consulting.de',
                            't.steiner@einstein-consulting.de',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default einsteinWhitelabel
