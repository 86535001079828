import React from 'react'
import { WidthContainer } from '../layout'
import { useTheme, Typography, Stack, Button, Box, DialogContent } from '@mui/material'
import HappyIllustration from './happy-illustration'
import ConfettiExplosion from 'react-confetti-explosion'
import useDialog from '../../../mixins/use-dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import T from '../../../components/typography/t'

const GuideOutroPage = React.forwardRef<HTMLDivElement, { assistanceIframeUrl?: string }>(
    ({ assistanceIframeUrl }, ref) => {
        const theme = useTheme()
        const [itemRepaired, setItemRepaired] = React.useState(false)
        const { isOpen, open, close } = useDialog()
        const { i18n } = useTranslation()
        return (
            <WidthContainer
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                }}
                ref={ref}
            >
                {itemRepaired ? (
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                zIndex: 1000,
                            }}
                        >
                            <HappyIllustration theme={theme} />
                            <Box
                                sx={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    position: 'absolute',
                                    zIndex: 1000,
                                    pointerEvents: 'none',
                                }}
                            >
                                <ConfettiExplosion
                                    width={document.body.offsetWidth}
                                    duration={4400}
                                />
                            </Box>
                        </Box>
                        <Typography
                            variant="h5"
                            component="h1"
                            fontSize={16}
                            fontWeight="600"
                            mb={1}
                        >
                            <T i18nKey="serviceDesk.ufixit.outro.enjoy" />
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            variant="h5"
                            component="h1"
                            fontSize={16}
                            fontWeight="600"
                            mb={1}
                        >
                            <T i18nKey="serviceDesk.ufixit.outro.didYouManage" />
                        </Typography>
                        <Stack
                            direction={{ sm: 'row', xs: 'column' }}
                            gap={2}
                            alignItems="center"
                            mt={4}
                        >
                            <Button onClick={open}>
                                <T i18nKey="serviceDesk.ufixit.outro.furtherAssistance" />
                            </Button>
                            <Button
                                onClick={() => {
                                    setItemRepaired(true)
                                }}
                                variant="contained"
                            >
                                <T i18nKey="serviceDesk.ufixit.outro.yesIAmHappy" />
                            </Button>
                        </Stack>
                    </>
                )}
                <Dialog onClose={close} open={isOpen} fullScreen>
                    <DialogTitle>
                        <T i18nKey="serviceDesk.ufixit.outro.findNearestStore" />
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <iframe
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                minHeight: '620px',
                            }}
                            src={`${
                                assistanceIframeUrl ??
                                'https://brandwidgets.outtra.com/brand-widget/vaude-23/shop-map/?outtrabrandId=158&'
                            }language=${i18n.language.slice(0, 2)}`}
                        ></iframe>
                    </DialogContent>
                </Dialog>
            </WidthContainer>
        )
    }
)

export default GuideOutroPage
