import React from 'react'
import styled from 'styled-components'
import { InspectorMultiStepFormContext } from '../../inspector-multi-step-form'
import StepFrameworkWrapper from '../components/step-framework-wrapper'
import PhotoStep, {
    PhotoStepHandle,
    UploadedFileInfo,
} from '../../../../service-desk/shared/steps/photo-step'
import generateRandomCode from '../../../../service-desk/shared/helper/generate-random-code'
import useCookie from '../../../../../mixins/use-cookie'
import brakeableWhitelabel from '../../../../../../shared/data/whitelabel/brakeable-whitelabel'

const PreviewImgBox = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PreviewImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`

const PhotoStepContext = () => {
    const childRef = React.useRef<PhotoStepHandle>(null)
    const { props, form, meta, error, ctx, uploadCareClient } = React.useContext(
        InspectorMultiStepFormContext
    )
    const onPhotoChosen = () => {
        props.nextSlide()
    }

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, fileUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(2)
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue((m: any) => ({ ...m, fileUpload: { progress: progression } }))
    }

    const setUploadedFileInfo = (info?: UploadedFileInfo) => {
        meta.setMetaValue((m: any) => ({ ...m, uploadedFileInfo: info }))
    }

    const [getMobileUploadIdFromCookies, setMobileUploadIdToCookies] = useCookie(
        'mobile-defect-photo-upload-id'
    )
    const mobileUploadId =
        getMobileUploadIdFromCookies() ??
        setMobileUploadIdToCookies(generateRandomCode(), { expires: 1 / 96 })

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled:
                    !form.formValues.inspectionImageUrl && !meta.value?.fileUpload?.progress,
            }}
        >
            {/* <PhotoStep
                onPhotoChosen={onPhotoChosen}
                inspectionImageUrl={form.formValues.inspectionImageUrl}
                setInspectionImageUrl={form.setInspectionImageUrl}
                allowRemindMeLater={ctx.allowRemindMeLater}
                allowGalleryUpload={ctx.allowGalleryUpload}
                onPhotoUploadFailed={onPhotoUploadFailed}
                setUploadProgress={setUploadProgress}
                uploadProgress={meta.value?.fileUpload?.progress}
                onRemindMeLaterSubmit={onRemindMeLaterSubmit}
                uploadCareClient={uploadCareClient}
            /> */}
            <PhotoStep
                ref={childRef}
                setOriginalImageUrl={form.setInspectionImageUrl}
                onPhotoUploadFailed={onPhotoUploadFailed}
                setUploadProgress={setUploadProgress}
                setUploadedFileInfo={setUploadedFileInfo}
                uploadProgress={meta.value?.fileUpload?.progress}
                uploadedFileInfo={meta.value?.uploadedFileInfo}
                uploadedPhotoUrl={form.formValues.inspectionImageUrl}
                uploadCareClient={uploadCareClient}
                brandId={brakeableWhitelabel.id}
                brandName={brakeableWhitelabel.name}
                mobileUploadId={mobileUploadId}
            />
        </StepFrameworkWrapper>
    )
}

export default PhotoStepContext
