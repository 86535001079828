import mammutProductCategoryTree from '../products/mammut-products'
import { WhitelabelStatus, type Whitelabel } from './types'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides'
import { repairServicesDe } from '../repair-services'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const mammutWhitelabel: Whitelabel = {
    id: '1nc0qt',
    status: WhitelabelStatus.InternalOnly,
    name: 'Mammut',
    theme: {
        palette: {
            primary: {
                main: '#23293D',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#E00C25',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#E00C25', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#E00C25', accent: '#23201F' }),
            getProgressbarColor: () => '#E00C25',
            getTextColor: () => '#23293D',
        },
    },
    logo: 'https://ucarecdn.com/47371dd9-c0a1-48fb-92c5-ecbcef9d2f90/',
    repairGuides: vaudeRepairGuides,
    repairServices: repairServicesDe,
    productCategoryTree: mammutProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.mammut.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'ram@ortlieb.de',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            proofOfPurchaseFields: [],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.mammut.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'ram@ortlieb.de',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default mammutWhitelabel
