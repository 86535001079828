import React from 'react'
import { RouteComponentProps } from 'react-router'
import whitelabels from '../../../../shared/data/whitelabel'
import WarrantyMultiStep from './warranty-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { submitWarrantyRequest } from 'wasp/client/operations'
import { WarrantyRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import { FormValues, WhitelabelWithWarrantyConfig } from './context'

const WarrantyModule = (props: RouteComponentProps<{ company: string }>) => {
    const company = props.match.params.company
    const urlParams = company.split('-')
    const companyId = urlParams[urlParams.length - 1]
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    const whitelabel = whitelabels.find((w) => w.id === companyId)

    if (!whitelabel || !whitelabel.config.warranty) {
        window.location.href = '//www.brakeable.com'
        return null
    }

    React.useEffect(() => {
        analytics.track(WarrantyRequestPageViewEvent, { isMobile, brandId: companyId, utmData })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        await submitWarrantyRequest({
            companyId: companyId,
            defectPhotoUrl: formValues.defectPhotoUrl!,
            pinpoint: formValues.pinpoint!,
            description: formValues.description!,
            product: formValues.selectedProduct!,
            productView: formValues.selectedProductView,
            receiptPhotoUrl: formValues.receiptPhotoUrl,
            proofOfPurchaseForm: formValues.proofOfPurchaseForm,
            contactForm: formValues.contactFormValues,
            utmData: utmData,
            anonymousUserId: analytics.user().anonymousId,
        })
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <WarrantyMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithWarrantyConfig}
                onSubmit={handleSubmit}
            />
        </ThemeProvider>
    )
}

export default WarrantyModule
