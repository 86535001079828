import React from 'react'
import { RouteComponentProps } from 'react-router'
import guides from './data/guides'
import { createTheme, styled, ThemeProvider, type Theme } from '@mui/material'
import Carousel from 'react-multi-carousel'
import GuideIntroPage from './guide-intro-page'
import GuideStepPage from './guide-step-page'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
    ProgressBar,
} from '../layout'
import GuideList from './guide-list'
import { Stack, Button, Box, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HomeIcon from '@mui/icons-material/Home'
import GuideOutroPage from './guide-outro-page'
import T from '../../../components/typography/t'
import whitelabels from '../../../../shared/data/whitelabel'

const CarouselInner = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const UfixitPage = (props: RouteComponentProps<{ guideId: string }>) => {
    const [started, setStarted] = React.useState(false)
    const [slideIndex, setSlideIndex] = React.useState(1)
    const carouselRef = React.useRef<Carousel>(null)
    const slideRef = React.useRef<HTMLDivElement>(null)
    const pageRef = React.useRef<HTMLDivElement>(null)
    const guideId = props.match.params.guideId
    const guide = guides[guideId]

    if (!guide) {
        return <GuideList />
    }

    const whitelabel = whitelabels.find((w) => w.id === guide.brandId)!
    const maxSteps = guide.steps.length + 1
    const finished = slideIndex === maxSteps

    const goNext = () => {
        carouselRef.current?.next(1)
    }

    const goBack = () => {
        carouselRef.current?.previous(1)
        if (carouselRef.current?.state.currentSlide === 0) {
            setStarted(false)
        }
    }

    const updateCarouselHeight = () => {
        if (carouselRef.current && carouselRef.current.containerRef.current && slideRef.current) {
            carouselRef.current!.containerRef.current!.style.height =
                slideRef.current!.offsetHeight + 'px'
        }
    }

    React.useEffect(() => {
        setTimeout(updateCarouselHeight, 1)
    }, [carouselRef.current, slideRef.current])

    const startGuide = () => {
        setStarted(true)
        setTimeout(updateCarouselHeight, 1)
    }

    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                } as any)
            }
        >
            <Page ref={pageRef}>
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <a
                            onClick={() => {
                                setSlideIndex(1), setStarted(false)
                            }}
                        >
                            <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                        </a>
                    </WidthContainer>
                </Header>
                <ProgressBar
                    $progress={(started ? slideIndex : 0) / maxSteps}
                    sx={{ position: 'sticky', top: '0', zIndex: 1 }}
                />
                {started && (
                    <WidthContainer
                        sx={{
                            height: 'auto',
                            position: 'sticky',
                            top: '8px',
                            zIndex: 1,
                            background: '#fff',
                            mt: 1,
                            mb: 0,
                            py: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            component="nav"
                        >
                            <Button
                                variant="outlined"
                                onClick={goBack}
                                sx={{
                                    boxShadow: 0,
                                    background: '#fff',
                                }}
                            >
                                {slideIndex === 1 ? <HomeIcon /> : <ArrowBackIcon />}
                            </Button>
                            {started && !finished && (
                                <Typography variant="body1" component="p" fontWeight="500">
                                    <T i18nKey="serviceDesk.ufixit.common.step" /> {slideIndex}
                                </Typography>
                            )}
                            <Button
                                variant="outlined"
                                disabled={slideIndex === maxSteps}
                                onClick={goNext}
                                sx={{
                                    boxShadow: 0,
                                    background: '#fff',
                                    opacity: finished ? 0 : 1,
                                    pointerEvents: finished ? 'none' : 'auto',
                                }}
                            >
                                <ArrowForwardIcon />
                            </Button>
                        </Stack>
                    </WidthContainer>
                )}
                <Content>
                    {!started ? (
                        <WidthContainer>
                            <GuideIntroPage guide={guide} onStartClick={startGuide} />
                        </WidthContainer>
                    ) : (
                        <>
                            <WidthContainer sx={{ padding: 0 }}>
                                <Carousel
                                    ref={carouselRef}
                                    responsive={{
                                        all: { items: 1, breakpoint: { max: 4000, min: 0 } },
                                    }}
                                    arrows={false}
                                    beforeChange={(nextSlide) => setSlideIndex(nextSlide + 1)}
                                    afterChange={(_, item) => {
                                        document.getElementById('root')?.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                >
                                    {guide.steps.map((step, index) => (
                                        <WidthContainer
                                            key={step.stepid}
                                            sx={{ height: 'auto' }}
                                            ref={index + 1 === slideIndex ? slideRef : null}
                                        >
                                            <CarouselInner>
                                                <GuideStepPage step={step} index={index + 1} />
                                            </CarouselInner>
                                        </WidthContainer>
                                    ))}
                                    <GuideOutroPage
                                        ref={maxSteps === slideIndex ? slideRef : null}
                                        assistanceIframeUrl={
                                            whitelabel.config.ufixit?.outro?.assistanceIframeUrl
                                        }
                                    />
                                </Carousel>
                            </WidthContainer>
                        </>
                    )}
                </Content>
                <Footer>
                    <WidthContainer sx={{ justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => window.close()}>
                            <T i18nKey="serviceDesk.ufixit.common.backToRequest" />
                        </Button>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </ThemeProvider>
    )
}

export default UfixitPage
