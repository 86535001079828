import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { Box, Stack, Button, IconButton, styled, Typography, Drawer, Link } from '@mui/material'
import T from '../../../../../components/typography/t'
// import DIYStep from './diy-step'
import { type RepairService } from '../../../../../../shared/data/repair-services'
import useDialog from '../../../../../mixins/use-dialog'
import { useIsDesktop } from '../../../../../mixins/media-query'
import CloseIcon from '@mui/icons-material/Close'
import SuggestionsContactForm, { type FormData } from './suggestions-contact-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'
import LabelledDelimiter from '../../../shared/components/labelled-delimiter'
import { useTranslation } from 'react-i18next'

const InnerDrawer = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
`

const DetailsCard = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    flex-grow: 1;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    border-radius: ${({ theme }) => theme.spacing(1)};
    position: relative;
`

const OneEstimation: React.FC<{
    selectedService: RepairService
    onDiyClick: () => void
    repairGuideCount: number
    onSubmit: (data: FormData) => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
}> = ({ selectedService, onDiyClick, repairGuideCount, onSubmit, contactForm, useForm }) => {
    const { i18n } = useTranslation()
    const isDesktop = useIsDesktop()
    const { isOpen, close, open } = useDialog()
    const maxPrice = Math.max(...selectedService.price)
    const minPrice = Math.min(...selectedService.price)
    const isPriceRange = maxPrice - minPrice > 0

    const language = i18n.language.slice(0, 2)
    const getRepairServiceTitle = (repairService: RepairService) => {
        if (repairService.translations && repairService.translations[language]) {
            return repairService.translations[language]
        }
        return repairService.title
    }

    const dynamicModalChildren = (
        <>
            <IconButton
                onClick={(e: any) => {
                    e.target.blur()
                    close()
                }}
                sx={{
                    position: 'absolute',
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                }}
            >
                <CloseIcon />
            </IconButton>
            <T
                variant="h3"
                fontWeight={500}
                color="primary"
                i18nKey="serviceDesk.repair.contactStep.form.title"
            />
            <SuggestionsContactForm
                onSubmit={onSubmit}
                contactForm={contactForm}
                useForm={useForm}
                maxAcceptedPrice={maxPrice}
            />
        </>
    )

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.costEstimationStep.title" mb={4} />
            <Stack
                direction="row"
                gap={8}
                justifyContent="center"
                width="100%"
                alignItems="flex-start"
            >
                <Box
                    sx={{
                        maxWidth: (theme) =>
                            isDesktop ? `calc(50% - ${theme.spacing(4)})` : '100%',
                    }}
                >
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.singleRepair.lead"
                        component="p"
                        variant="body1"
                        mb={2}
                    />
                    <Typography variant="h2" textAlign="center" fontWeight={600} color="primary">
                        {isPriceRange
                            ? `${minPrice.toFixed(2)}\u00A0€ - ${maxPrice.toFixed(2)}\u00A0€`
                            : `${maxPrice.toFixed(2)}\u00A0€`}
                    </Typography>
                    <Typography variant="body2" textAlign="center" color="grey.500">
                        {getRepairServiceTitle(selectedService)}
                    </Typography>
                    {!isOpen && (
                        <>
                            <Stack mt={4} gap={2} justifyContent="stretch" width="100%">
                                <Button variant="contained" onClick={open}>
                                    <T i18nKey="serviceDesk.repair.costEstimationStep.repairAction" />
                                </Button>
                                {repairGuideCount > 0 && (
                                    <Button onClick={onDiyClick}>
                                        <T i18nKey="serviceDesk.repair.costEstimationStep.diyAction" />
                                    </Button>
                                )}
                                <LabelledDelimiter sx={{ my: 0 }}>
                                    <T component="span" i18nKey="common.or" />
                                </LabelledDelimiter>
                                <Link
                                    sx={{
                                        alignSelf: 'center',
                                        color: 'grey.200',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <T i18nKey="serviceDesk.repair.costEstimationStep.customerSupportAction" />
                                </Link>
                            </Stack>
                        </>
                    )}
                </Box>
                {!isDesktop ? (
                    <Drawer
                        open={isOpen}
                        onClose={close}
                        PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
                    >
                        <InnerDrawer>{dynamicModalChildren}</InnerDrawer>
                    </Drawer>
                ) : (
                    isOpen && <DetailsCard>{dynamicModalChildren}</DetailsCard>
                )}
            </Stack>
        </>
    )
}

export default OneEstimation
