import defaultRepairServices from '../repair-services'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides'
import theme from '../../../client/theme'
import defaultProductCategoryTree from '../products'
import { WhitelabelStatus, type Whitelabel } from './types'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const brakeableWhitelabel: Whitelabel = {
    id: 'va5z1d',
    status: WhitelabelStatus.InternalOnly,
    name: 'Brakeable',
    theme: theme,
    logo: 'https://ucarecdn.com/9e0bb50d-8d9f-4779-ae66-f32c5b04d9ad/',
    repairGuides: vaudeRepairGuides,
    repairServices: defaultRepairServices,
    productCategoryTree: defaultProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.brakeable.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'kim@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            proofOfPurchaseFields: [],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.brakeable.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'kim@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default brakeableWhitelabel
