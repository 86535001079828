import defaultRepairGuides from '../repair-guides'
import defaultRepairServices from '../repair-services'
import duotoneProductCategoryTree from '../products/duotone-products'
import { WhitelabelStatus, type Whitelabel } from './types'

const contactFields = [
    [
        {
            key: 'Name',
            label: 'serviceDesk.shared.contactStep.form.nameLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            options: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Phone',
            label: 'serviceDesk.shared.contactStep.form.phoneLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'MaxRepairCost',
            label: 'serviceDesk.shared.contactStep.form.maxRepairCostLabel',
            options: { required: true },
            fieldProps: { type: 'number' },
        },
    ],
]

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const duotoneWhitelabel: Whitelabel = {
    id: '8ijarl',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Duotone',
    theme: {
        palette: {
            primary: {
                main: '#0d242b',
                contrastText: '#fff',
            },
            secondary: {
                main: '#05A8C4',
                contrastText: '#000',
            },
            getCrosshairColors: () => ({ stroke: '#0d242b', fill: '#05A8C4' }),
            getIllustrationColors: () => ({
                primary: '#0d242b',
                accent: '#05A8C4',
            }),
            getProgressbarColor: () => '#05A8C4',
            getTextColor: () => '#0d242b',
        },
    },
    logo: 'https://ucarecdn.com/e5c6c239-3df7-4e27-bf23-c386f5a4149f/',
    repairGuides: defaultRepairGuides,
    repairServices: defaultRepairServices,
    productCategoryTree: duotoneProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: contactFields,
            },
            activateCostEstimation: false,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle2',
                links: [
                    {
                        label: 'poc.support.repairOutroStep.locationAction',
                        href: 'https://maps.app.goo.gl/xTPxhvH59A67JAhPA',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: contactFields,
            },
            proofOfPurchaseFields: [
                [
                    {
                        key: 'Orderreference',
                        label: 'serviceDesk.warranty.purchaseConfirmationStep.form.orderReferenceLabel',
                        options: { required: true },
                    },
                ],
                [
                    {
                        key: 'ArticleNumber',
                        label: 'serviceDesk.warranty.purchaseConfirmationStep.form.articleNumberLabel',
                        options: {
                            required: true,
                            helperPopup: {
                                title: 'serviceDesk.warranty.purchaseConfirmationStep.form.articleNumberWhereToFindTitle',
                                imgUrl: 'https://ucarecdn.com/d9a9ad9d-840f-4073-b2c8-9193bbeb96b3/-/preview/842x595/',
                            },
                        },
                    },
                ],
            ],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//penguin.swiss',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default duotoneWhitelabel
