import { TranslationsT } from './de'

const en: TranslationsT = {
    common: {
        next: 'Next',
        back: 'Back',
        cancel: 'Cancel',
        submit: 'Submit',
        go: 'Go',
        or: 'Or',
        logout: 'Logout',
        loading: 'Loading...',
        error: 'Something went wrong',
        copied: 'Copied!',
        language: 'Language',
        requestTypes: {
            all: 'All',
            warranty: 'Warranty',
            repair: 'Repair',
        },
    },
    admin: {
        tatajuba: {
            title: 'Service Desk',
        },
        newCompanyRepresentative: {
            title: 'Invite new company',
            companyNameLabel: 'Company name',
            companyRepresentativeNameLabel: 'Employee name',
            companyRepresentativeEmailLabel: 'Employee email',
            buttonLabel: 'Invite',
        },
        unverifiedUsers: {
            title: 'Unverified users',
            table: {
                company: 'Company',
                representative: 'Representative',
                email: 'E-Mail',
                link: 'Invitation link',
            },
        },
        companies: {
            title: 'Companies',
            table: {
                id: 'Id',
                company: 'Company',
                representative: 'Representative',
                representativeEmail: 'E-Mail',
                apps: 'Apps',
                productCount: 'Product count',
            },
        },
    },
    company: {
        onboarding: {
            form: {
                welcome: 'Welcome!',
                lead: 'We are glad to have you on board.',
                subLead: 'Please activate your account:',
                email: {
                    label: 'E-Mail',
                    placeholder: 'E-Mail',
                    helperText:
                        'Please use the e-mail address to which you received this invitation link.',
                },
                password: {
                    label: 'Password',
                    placeholder: 'Set a new, secure password',
                },
                requirements: {
                    title: 'Password requirements:',
                    length: 'At least 8 characters',
                    casing: 'Lower and upper case characters',
                    number: 'At least one number',
                    special: 'At least one special character',
                },
                action: 'Create account',
            },
            introduction: {
                title: 'Welcome to the Inspector Platform. Your journey towards enhanced product quality and enriched customer experiences starts here. We are glad to have you on board and look forward to supporting your success.',
                action: 'Let’s go',
            },
        },
        common: {
            filterbar: {
                search: {
                    label: 'Search',
                    placeholder:
                        "Search for e-mail, description or date (e.g. '11. november' oder 'january')",
                },
                unseen: {
                    showOnlyUnseen: 'Show only new Inspections',
                    showAll: 'Show all Inspections',
                },
                share: 'Share insights',
            },
        },
        inspector: {
            home: {
                products: {
                    title: 'Products',
                    action: 'Add new product',
                    productList: {
                        openFeatureTest: 'Feature Test',
                        openRainTest: 'Rain Test',
                        copyInspectorLink: {
                            copyDesktop: 'Copy Inspector-Link',
                            copyMobile: 'Inspector-Link',
                            copied: 'Inspector-Link copied',
                        },
                        deleteProduct: {
                            headline: 'Are you sure?',
                            confirmText:
                                'Do you really want to delete the product <strong>"{{productName}}"</strong> <strong>irrevocably</strong>? All data will be deleted and cannot be restored.',
                            cancel: 'Cancel',
                            delete: 'Delete',
                        },
                    },
                    newProduct: {
                        title: 'Add new product',
                        form: {
                            name: {
                                label: 'Product name',
                                required: 'Product name is required',
                            },
                            cancelButton: {
                                label: 'Cancel',
                            },
                            saveButton: {
                                label: 'Create product',
                                saving: 'Saving...',
                                validation: {
                                    nameRequired: 'Product name is required',
                                    imageRequired: 'Product image is required',
                                },
                            },
                            photoStep: {
                                title: 'Product image',
                                subtitle:
                                    'Take a photo of your product with your camera or upload one from your gallery.',
                                takePhotoButton: 'Take photo',
                                uploadPhotoButton: 'Upload photo',
                            },
                            infoStep: {
                                title: 'Product information',
                                subtitle: 'Please enter the name of your product.',
                                name: {
                                    label: 'Product name',
                                    placeholder: 'Product name',
                                },
                                brand: {
                                    label: 'Brand',
                                    placeholder: 'Brand',
                                },
                                gender: {
                                    label: 'Gender',
                                    men: 'Men',
                                    women: 'Women',
                                    diverse: 'Diverse',
                                },
                                age: {
                                    label: 'Age',
                                    placeholder: 'Age',
                                },
                                laminate: {
                                    label: 'Laminate',
                                    two: '2 Layer',
                                    three: '3 Layer',
                                    twoLiner: '2 + Liner',
                                    otter: 'Otter',
                                },
                            },
                            summaryStep: {
                                title: 'Summary',
                                subtitle:
                                    'Please check the information you have entered and confirm the creation of the product.',
                                loading:
                                    'The image is being uploaded and optimized. This may take a few seconds.',
                                unhappy: 'Not happy?',
                                useOriginalBtn: 'Use original image instead',
                                useOptimizedBtn: 'Use optimized image',
                                nextBtn: 'Create',
                            },
                        },
                    },
                },
                share: {
                    exportProductList: 'export product list',
                    printProductCards: 'print product cards',
                    orderCompanyCard: 'order company card',
                    copyCompanyLink: 'copy company overview link',
                    copyCompanySupportLink: 'copy company support link',
                    companyLinkCopied: 'link copied',
                },
                onboarding: {
                    title: 'Products',
                    action: 'Create product',
                },
            },
            productDashboard: {
                backToOverview: 'Back to overview',
                configuration: {
                    gender: 'Gender',
                    age: 'Age',
                    laminate: 'Laminate',
                },
                keyFacts: {
                    pageViews: 'Page views',
                    problems: 'Problems',
                    happySubmissionFeedback: 'Nothing to report',
                    conversion: 'Conversion',
                },
                noDescription: 'No description available',
                aiFilter: 'AI filters',
                originFilter: 'Origin',
                detail: {
                    defectDescription: 'Defect description',
                    contextDescription: 'Product usage',
                    contact: 'Contact e-mail for follow up:',
                },
                noSubmissions: {
                    title: 'No inspections yet',
                    subTitle:
                        'As soon as your customers have carried out an inspection, they will be displayed here.',
                    copyInspectionLink: 'Copy inspector link',
                    copied: 'Link copied',
                },
            },
        },
        report: {
            title: 'Inspections',
        },
        userManagement: {
            users: {
                title: 'Users',
                action: 'add new user',
                userList: {
                    subtitle: 'Products: {{ productCount }} | Inspections: {{ inspectionCount }}',
                },
            },
        },
        support: {
            supportRequests: {
                title: 'Support requests',
                kanban: {
                    open: 'Open',
                    inProgress: 'In Progress',
                    done: 'Done',
                },
                noSupportRequestsFallback: 'You don’t have any support requests yet. Good job!',
            },
        },
        companySettings: {
            account: {
                title: 'Account',
                newEmployeeForm: {
                    name: 'Name',
                    email: 'E-Mail',
                    button: 'Invite',
                },
                logoForm: {
                    title: 'Upload company logo',
                    helperText: 'Ideally a logo without distance to the border',
                    loading: 'File is being uploaded...',
                    success: 'Logo successfully uploaded',
                    failed: 'Something went wrong',
                },
            },
            settings: {
                title: 'Settings',
                basicSettings: {
                    photoOrigin: {
                        title: 'Photo origin',
                        lead: 'Should users only be able to take photos with the camera or also upload them from the gallery?',
                        cameraAndGallery: 'Camera and gallery possible',
                        cameraOnly: 'Only camera possible',
                    },
                    emailMandatory: {
                        title: 'E-Mail as mandatory field',
                        lead: 'Should users be required to provide their e-mail address or should this be optional?',
                        mandatory: 'E-Mail is a mandatory field',
                        optional: 'E-Mail is optional',
                    },
                    emailNotifications: {
                        title: 'E-Mail notifications',
                        lead: 'Do you want to receive e-mail notifications about new inspections?',
                        enabled: 'E-Mail notifications are enabled',
                        disabled: 'E-Mail notifications are disabled',
                    },
                },
                customerSettingsOnlyWarning:
                    'These settings are only available for customer version of the inspector app.',
                customerSettings: {
                    postInspectionRedirect: {
                        title: 'Redirect after inspection',
                        lead: 'Where should the user be redirected after an inspection?',
                        label: 'Redirect after inspection',
                        companyPage: 'Company page',
                        restart: 'Start screen',
                        companyPageValidation: {
                            required: 'Company page is required.',
                            invalid: 'Company page is invalid.',
                        },
                    },
                    allowHappySubmissions: {
                        title: 'Allow "Nothing to report"',
                        lead: 'Should users be able to save an inspection without a comment?',
                        enabled: '"Nothing to report" possible',
                        disabled: '"Nothing to report" not possible',
                    },
                    allowRemindMeLater: {
                        title: 'Allow "Remind me later"',
                        lead: 'Should users be able to request an e-mail reminder to submit an inspection later?',
                        enabled: '"Remind me later" possible',
                        disabled: '"Remind me later" not possible',
                    },
                    allowContactRequests: {
                        title: 'Allow contact requests',
                        lead: 'Should users be able to request contact from your company?',
                        enabled: 'Contact requests possible',
                        disabled: 'Contact requests not possible',
                    },
                    showDashboardAnalytics: {
                        title: 'Show dashboard analytics',
                        lead: 'Do you want to see analytics about your inspections in the dashboard?',
                        enabled: 'Dashboard analytics are enabled',
                        disabled: 'Dashboard analytics are disabled',
                    },
                },
                save: 'Save',
                saving: 'Saving...',
                success: 'Settings successfully saved',
                error: 'There was an error saving the settings',
            },
        },
    },
    publicCompany: {
        profile: {
            title: 'Product inspection',
            lead: 'Thank you for helping us making longer lasting products. Please choose the product you want to inspect below:',
            help: 'Help',
        },
        introCarousel: {
            title: 'Intro to Inspector',
            s1: {
                title: 'Choose product',
                lead: 'Choose the product you want to inspect.',
            },
            s2: {
                title: 'Mark problem area',
                lead: 'Mark the area where you found a defect or have a suggestion for improvement.',
            },
            s3: {
                title: 'Take photo',
                lead: 'Take a photo of the problem area.',
            },
            s4: {
                title: 'Describe problem',
                lead: 'Describe the defect or suggestion for improvement.',
            },
            s5: {
                title: 'Tell the story',
                lead: 'Tell us the story of your product and the problem.',
            },
        },
        support: {
            title: 'Repair request',
            lead: 'If something is wrong with your product, fill out this request so we can get in touch with you and find a solution together.',
        },
    },
    inspector: {
        desktop: {
            title: 'Please open the INSPECTION on your mobile device',
            description: 'Scan the QR code with your mobile device',
        },
        mobile: {
            navigation: {
                from: 'from',
                submit: 'Submit',
            },
            intro: {
                title: 'Product Inspection',
                description:
                    'If you have an improvement suggestion or defect on your product, conduct an inspection.',
                action: 'Start inspection',
                nothingToReport: {
                    action: 'nothing to report',
                },
            },
            pinpoint: {
                title: 'Mark problem area',
                description:
                    'Click on the corresponding area in the image to mark a <strong>suggestion for improvement</strong> or <strong>defect</strong>',
            },
            photo: {
                title: 'Take a photo of the problem',
                description: 'Take a <strong>photo</strong> of the area you just marked.',
                preview: 'Preview',
                action: 'Take photo',
                reAction: 'Take new photo',
                itemNotAvailable: 'Item not available at the moment',
                cancelled: {
                    description:
                        'You stopped recording. If it was because you saw black screen, it might be due to permissions. You can see how you can customize these at the following link.',
                    showMe: 'Show me how',
                    dismiss: 'close',
                },
                remindMeLater: {
                    title: 'Inspect product later',
                    lead: 'You don’t have the product with you? No problem. We‘ll send you a reminder mail to inspect it at a later point.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Time of reminder',
                    outro: 'Your e-mail address will only be used for this reminder.',
                    submit: 'Remind me later',
                    cancel: 'Cancel',
                },
            },
            description: {
                title: 'Describe the problem',
                description: 'Describe the <strong>defect</strong> of the product.',
                label: 'Description',
                placeholder: 'Problem description',
            },
            contextDescription: {
                title: 'More information',
                description:
                    'Describe the <strong>history</strong> of your <strong>product</strong> and <strong>problem</strong>.',
                label: 'Description',
                placeholder: 'Product / problem history',
            },
            contact: {
                title: 'Provide contact information',
                description:
                    'Please provide your E-Mail so we can contact you for further <strong>questions about your inspection</strong>',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Please contact me with a solution for this issue',
                photoUploadLabel: 'Photo-Upload',
            },
            outro: {
                title: 'Thank you!',
            },
        },
    },
    support: {
        desktop: {
            title: 'Please open the repair request on your mobile device',
            description: 'Scan the QR code with your mobile device',
        },
        mobile: {
            navigation: {
                from: 'from',
                submit: 'Submit',
            },
            intro: {
                title: 'Repair Request',
                description: 'If you have an defect on your product, file a repair request.',
                action: 'Start request',
                nothingToReport: {
                    action: 'nothing to report',
                },
            },
            pinpoint: {
                title: 'Mark problem area',
                description:
                    'Click on the corresponding area in the image to mark the <strong>defect</strong>',
            },
            photo: {
                title: 'Take a photo of the problem',
                description: 'Take a <strong>photo</strong> of the area you just marked.',
                preview: 'Preview',
                action: 'Take photo',
                reAction: 'Take new photo',
                itemNotAvailable: 'Item not available at the moment',
                cancelled: {
                    description:
                        'You stopped recording. If it was because you saw black screen, it might be due to permissions. You can see how you can customize these at the following link.',
                    showMe: 'Show me how',
                    dismiss: 'close',
                },
                remindMeLater: {
                    title: 'Inspect product later',
                    lead: 'You don’t have the product with you? No problem. We‘ll send you a reminder mail to inspect it at a later point.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Time of reminder',
                    outro: 'Your e-mail address will only be used for this reminder.',
                    submit: 'Remind me later',
                    cancel: 'Cancel',
                },
            },
            description: {
                title: 'Describe the problem',
                description: 'Describe the <strong>defect</strong> of your product.',
                label: 'Description',
                placeholder: 'Problem description',
            },
            orderReference: {
                title: 'Enter your order reference',
                description: 'Please enter your <strong>order reference</strong>.',
                label: 'Order reference',
                placeholder: 'Order reference',
            },
            contact: {
                title: 'Provide contact information',
                description:
                    'Please provide your E-Mail so we can can get back to you <strong>about your repair request</strong>.',
                hint: 'Use the e-mail address you have used to purchase the product if possible.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Please contact me with a solution for this issue',
                photoUploadLabel: 'Photo-Upload',
            },
            outro: {
                title: 'Thank you!',
            },
        },
    },

    poc: {
        support: {
            linktree: {
                title: 'How Can We Help You Today?',
                subtitle: 'Select an Option Below to Get Started',
                repair: 'Repair request',
                repairSub: 'Request a repair request if you have a defect in your product',
                warranty: 'Warranty claim',
                warrantySub: 'Report an issue with your product under warranty',
                feedback: 'Product Feedback',
                feedbackSub: 'Share your suggestions or criticism for our products',
                diy: 'Do it yourself',
                diySub: 'Fix the defect in your product with our DIY guides',
            },
            repairIntroStep: {
                title: 'Repair Request',
                description: 'Have a defect in your product? File a repair request here.',
                action: 'Start Repair Request',
                backAction: 'Back to Start',
                multipleCapturingInfo:
                    'To request multiple repairs, you can attach additional requests at the end.',
            },
            feedbackIntroStep: {
                title: 'Product Feedback',
                description:
                    'If you have a suggestion for improvement or criticism of your product, you can share it with us here.',
                action: 'Submit Product Feedback',
                backAction: 'Back to Start',
                multipleCapturingInfo:
                    'To provide multiple feedbacks, you can attach additional feedbacks at the end.',
            },
            warrantyIntroStep: {
                title: 'Warranty Claim',
                description: 'Have an issue with your product? Report a warranty claim here.',
                action: 'Report Warranty Claim',
                backAction: 'Back to Start',
            },
            diyIntroStep: {
                title: 'Do it yourself',
                description: 'Have a defect in your product? Fix it yourself.',
                action: 'Find a guide',
                backAction: 'Back to Start',
            },
            emailStep: {
                title: 'E-Mail',
                subtitle:
                    'Please provide your e-mail address so we can identify your request and get back to you promptly.',
                repairSubtitle:
                    'Please provide your e-mail address so we can send the order confirmation and shipping label.',
                feedbackSubtitle:
                    'Please leave us your e-mail that we can get in touch with you for potential follow-up questions.',
                label: 'E-Mail',
                placeholder: '',
            },
            productStep: {
                title: 'Which product do you need help with?',
                subtitle:
                    '*If you need help with multiple items, you can include them at the end. ',
                form: {
                    productReference: 'Enter your item reference',
                },
                categories: {
                    all: 'All',
                    apparel: 'Apparel',
                    upperwear: 'Upperwear',
                    tshirt: 'T-Shirts',
                    pullover: 'Pullovers',
                    hoodie: 'Hoodie',
                    longsleeve: 'Longsleeve',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jacket: 'Jackets',
                    bikini: 'Bikini',
                    pants: 'Pants',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skiing Pants',
                    accessories: 'Accessories',
                    beanie: 'Beanies',
                    gloves: 'Gloves',
                    shoes: 'Shoes',
                    hikingShoes: 'Hiking Shoes',
                    snowBoots: 'Snow Boots',
                    sportShoes: 'Sport Shoes',
                    sneakers: 'Sneakers',
                    flipflop: 'Flip Flops',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Tents',
                    campingChairs: 'Camping Chairs',
                    mats: 'Mats and Mattresses',
                    sleepingBags: 'Sleeping Bags',
                    hiking: 'Hiking',
                    backpack: 'Backpacks',
                    hikingPole: 'Hiking Poles',
                    watersport: 'Watersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Men Shell Pants',
                        womenShellPants: 'Women Shell Pants',
                        menShellJacket: 'Men Shell Jacket',
                        womenShellJacket: 'Women Shell Jacket',
                    },
                },
                views: {
                    front: 'Front',
                    back: 'Back',
                    bottom: 'Top',
                    top: 'Bottom',
                    side: 'Side',
                    inside: 'Inside',
                },
            },
            descriptionStep: {
                title: 'Describe the problem',
                subtitle:
                    'Please describe the issue with your product in detail so we can assist you effectively.',
                label: 'Description of the problem',
                placeholder: '',
            },
            PhotoStep: {
                title: 'Upload a Photo of the Problem',
                QrCodeAlternativeTitle: 'Scan QR Code',
                QrCodeAlternative:
                    'Alternatively, you can also scan the QR code to take a photo with your phone.',
                dropzone: {
                    label: 'Drag and drop a Photo to upload',
                    description:
                        'For best results, upload clear, high-quality Photos of the Problem',
                    dropLabel: 'Drop the Photo here',
                    button: 'Select Photo',
                },
                mobile: {
                    label: 'Take a Photo',
                    description:
                        'For best results, upload clear, high-quality Photos of the Problem',
                    takePhotoButton: 'Take photo',
                    uploadPhotoButton: 'Upload photo',
                    retakeAction: 'Retake Photo',
                },
            },
            diyStep: {
                title: 'DIY Guides',
                subtitle:
                    'You might be able to resolve the issue yourself using the following guides:',
                guides: {
                    howToWash: {
                        title: 'How to wash and dry my membrane-jacket/pants?',
                    },
                    howToRepairHole: {
                        title: 'How to repair a hole in woven textile?',
                    },
                    howToRepairQuickLock: {
                        title: 'How to repair the Quick-Lock system on your bike bag yourself.',
                    },
                    clickHere: 'Click here for a step-by-step guide',
                },
                skip: 'Skip',
                guideBy_one: 'Guide by:',
                guideBy_other: 'Guides by:',
                checkoutGuideAction: 'Checkout Guide',
                nothingFoundSubtitle: 'Sadly, we could not find a matching repair guide.',
                notMatching: 'No suitable guide included?',
                requestNewGuideAction: 'Request a new DIY Guide',
                newGuideActionRequested: 'Request sent',
                findeRepairCenterAction: 'Get Local Expert Help',
                goBackAction: 'Edit your problem description',
                checkoutRepairOfferAction: 'Checkout repair offer',
            },
            costEstimationStep: {
                title: 'Cost estimation',
                costRange: {
                    title: 'Estimated costs',
                    title_other: 'Repair cost range',
                    subtitle: 'These estimated repair costs are based on your description.',
                },
                assignedServices: {
                    title: 'Assigned services',
                    subtitle: "Here are the potential repair services we' have identified for you",
                },
                diyAction: 'I prefer a DIY solution',
                repairAction: 'I want to repair my item',
            },
            solutionStep: {
                linktree: {
                    title: 'Repair Options',
                    diyTitle: 'Explore our DIY Solutions',
                    diySubtitle: 'Try fixing the issue yourself with our guides',
                    servicesTitle: 'Review our cost estimation',
                    servicesSubtitle:
                        'Check the estimated repair costs based on your defect description',
                },
                waitingForPhotoUpload: 'Wait for the photo upload to complete...',
            },
            contactStep: {
                title: 'Your Contact Details',
                form: {
                    title: 'Enter your details',
                    nameLabel: 'Name',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Phone',
                    maxRepairCostLabel: 'Maximum repair costs',
                    addressLabel: 'Address',
                    zipCodeLabel: 'Zip Code',
                    cityLabel: 'City',
                    countryLabel: 'Country',
                    commentLabel: 'Comment',
                    purchaseLocationLabel: 'Purchase Location',
                    purchaseDateLabel: 'Purchase Date',
                    orderReferenceLabel: 'Order Reference',
                    acceptTermsLabel: 'I agree to a repair up to the specified price limit',
                    addMoreDefects: 'Add more requests',
                    submit: 'Request Repair Offer',
                },
            },
            servicesStep: {
                title: 'Cost estimation',
                subtitle:
                    'Based on your problem description, the estimated repair cost is as follows:',
                price: 'Price: {{ price }}',
                aiSuggestionLead: 'View assigned services',
                remainingLead: 'Choose services manually',
                costConfirmationPre: 'I agree to a repair cost of up to',
                costConfirmationLabel: 'Cost Ceiling',
            },
            pinpointStep: {
                progressResetWarning: 'If you go back, all your entered data will be reset',
                backToOverview: 'Back to Start',
            },
            claimProbabilityStep: {
                title: 'Warranty Coverage',
                subtitle:
                    'Based on your description, the chance of this issue being covered by our warranty is:',
                knowWhy: 'Find out why',
                whyNot: 'Low Probability: This issue is less likely to be covered under warranty.',
                why: 'High Probability: We offer a lifetime waterproof guarantee and cover the cost of repair if your product is no longer waterproof.',
                alternativeSteps:
                    'We recommend trying the following DIY solutions or checking the repair costs.',
            },
            warrantyCostEstimationStep: {
                title: 'Cost Estimation',
                subtitle:
                    'If the issue is not covered by warranty, the estimated repair cost would be:',
            },
            repairOutroStep: {
                title: 'Thank You',
                subtitle: 'We will respond to you with an offer soon.',
                subtitle2: 'Please bring your defect Kite to our Shop.',
                locationAction: 'Google Maps',
                restartAction: 'Capture another defect',
                action: 'Back to Start',
            },
            feedbackOutroStep: {
                title: 'Thank You',
                subtitle: 'We appreciate your valuable feedback and strive to improve.',
                restartAction: 'Capture another feedback',
                action: 'Back to Start',
            },
            warrantyOutroStep: {
                title: 'Thank You',
                subtitle: 'We will get back to you soon.',
                action: 'Back to Start',
            },
        },
    },
    serviceDesk: {
        repair: {
            productStep: {
                title: 'Which product do you need help with?',
                productNotFoundLabel: 'Product not found?',
                productNotFoundAction: 'Request a repair offer for a different product',
            },
            pinpointStep: {
                progressResetWarning: 'If you go back, all your entered data will be reset',
                backToOverview: 'Back to Start',
            },
            solutionStep: {
                linktree: {
                    title: 'Repair Options',
                    diyTitle: 'Explore our DIY Solutions',
                    diySubtitle: 'Try fixing the issue yourself with our guides',
                    servicesTitle: 'Review our cost estimation',
                    servicesSubtitle:
                        'Check the estimated repair costs based on your defect description',
                    contactFormTitle: 'Request a repair offer',
                    contactFormSubtitle: 'We will get back to you with an offer.',
                    storeFinderTitle: 'Service experts near you',
                    storeFinderSubtitle: 'Get on-site support from one of our service experts.',
                },
                waitingForPhotoUpload: 'Wait for the photo upload to complete...',
            },
            costEstimationStep: {
                title: 'Cost estimation',
                costRange: {
                    lead: 'The costs can vary depending on the extent of the repair and the condition of your product in the following range:',
                    lead_other:
                        'The costs can vary depending on the extent of the repair and the condition of your product in the following range:',
                },
                singleRepair: {
                    lead: 'The cost for this Repair Service could be:',
                },
                costApproval: {
                    title: 'Cost Approval',
                    lead: 'Choose up to which price you want to approve the repair without asking us back, so we can process your request as quickly as possible:',
                },
                approvalCheckbox:
                    'I agree to the maximum repair costs of {{ maxPrice }} € and the repair terms.',
                diyAction: 'I prefer a DIY solution',
                repairAction: 'I want to repair my item',
                customerSupportAction: 'I want to contact the customer support',
            },
            contactStep: {
                title: 'Your Contact Details',
                form: {
                    title: 'Enter your details',
                    submit: 'Request Repair Offer',
                },
            },
            outroStep: {
                title: 'Thank you!',
                subtitle: 'We will respond to you with an offer soon.',
                subtitle2: 'Please bring your defect Kite to our Shop.',
                links: {
                    backToStore: 'Back to the Shop',
                },
            },
        },
        warranty: {
            productStep: {
                title: 'For which product do you want to report a warranty claim?',
                productNotFoundLabel: 'Product not found?',
                productNotFoundAction: 'Submit warranty request for a different product',
            },
            purchaseConfirmationStep: {
                linktree: {
                    title: 'Provide proof of purchase',
                    photoTitle: 'Upload photo of the receipt',
                    photoSubtitle: 'Attach a photo of the receipt',
                    manualTitle: 'Enter order number manually',
                    manualSubtitle: 'You can find this in your online order or on your receipt',
                    notAvailable: {
                        delimiter: 'Do you not have a proof of purchase?',
                        button: 'Proceed without proof of purchase',
                    },
                },
                photo: {
                    title: 'Upload photo of the receipt',
                },
                form: {
                    title: 'Enter proof of purchase',
                    orderReferenceLabel: 'Order number',
                    articleNumberLabel: 'Article number',
                    articleNumberWhereToFindTitle: 'Where can I find the article number?',
                },
            },
            contactStep: {
                title: 'Your Contact Details',
                form: {
                    title: 'Enter your details',
                    submit: 'Report warranty case',
                },
            },
            solutionStep: {
                waitingForPhotoUpload: 'The photos are still being uploaded...',
            },
            outroStep: {
                title: 'Thank you!',
                subtitle: 'We will get in touch with you soon.',
            },
        },
        shared: {
            productStep: {
                form: {
                    productReferenceLabel: 'Enter your item reference',
                },
                categories: {
                    all: 'All',
                    apparel: 'Apparel',
                    upperwear: 'Upperwear',
                    tshirts: 'T-Shirts',
                    tshirt: 'T-Shirt',
                    pullovers: 'Pullovers',
                    pullover: 'Pullover',
                    hoodie: 'Hoodie',
                    longsleeve: 'Longsleeve',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jackets: 'Jackets',
                    jacket: 'Jacket',
                    bikini: 'Bikini',
                    pants: 'Pants',
                    pant: 'Pants',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skiing Pants',
                    skiingPant: 'Skiing Pants',
                    accessories: 'Accessories',
                    beanie: 'Beanie',
                    gloves: 'Gloves',
                    shoes: 'Shoes',
                    hikingShoes: 'Hiking Shoe',
                    snowBoots: 'Snow Boot',
                    sportShoes: 'Sport Shoe',
                    sneakers: 'Sneaker',
                    flipflop: 'Flip Flops',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Tents',
                    tent: 'Tent',
                    campingChairs: 'Camping Chairs',
                    campingChair: 'Camping Chair',
                    mats: 'Mats and Mattresses',
                    mat: 'Mat or Mattress',
                    sleepingBags: 'Sleeping Bags',
                    sleepingBag: 'Sleeping Bag',
                    hiking: 'Hiking',
                    backpacks: 'Backpacks',
                    backpack: 'Backpack',
                    hikingPoles: 'Hiking Poles',
                    hikingPole: 'Hiking Pole',
                    watersport: 'Watersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Men Shell Pants',
                        womenShellPants: 'Women Shell Pants',
                        menShellJacket: 'Men Shell Jacket',
                        womenShellJacket: 'Women Shell Jacket',
                    },
                    bikeBag: 'Bike Bag',
                    travelBag: 'Travel Bag',
                    luggageRack: 'Luggage Rack',
                    handlebarBag: 'Handlebar Bag',
                    frameBag: 'Frame Bag',
                    saddleBag: 'Saddle Bag',
                    bikeBagBack: 'Bike Bag Back',
                    bikeBagFront: 'Bike Bag Front',
                    luggageRackBag: 'Luggage Rack Bag',
                    bags: 'Bags',
                    shoulderBag: 'Shoulder Bag',
                    messengerBag: 'Messenger Bag',
                    laptopBag: 'Laptop Bag',
                    toteBag: 'Tote Bag',
                    freitagTravelBag: 'Freitag Travel Bag',
                },
            },
            pinpointStep: {
                title: 'Mark problem area',
                views: {
                    front: 'Front',
                    back: 'Back',
                    bottom: 'Top',
                    top: 'Bottom',
                    side: 'Side',
                    inside: 'Inside',
                },
            },
            photoStep: {
                title: 'Upload a Photo of the Problem',
                mobilePhotoInput: {
                    label: 'Take a Photo',
                    description: 'For best results, upload a clear, high-quality Photo',
                    takePhotoButton: 'Take photo',
                    uploadPhotoButton: 'Upload photo',
                },
                photoDragDrop: {
                    label: 'Upload a Photo from the Computer',
                    description:
                        'For best results, upload clear, high-quality Photos of the Problem',
                    dropLabel: 'Drop the Photo here',
                    button: 'Select Photo',
                },
                qrPhotoUpload: {
                    title: 'Upload a photo from your Phone',
                    text: 'Just scan the QR Code with your Phone and upload a Photo',
                },
            },
            descriptionStep: {
                title: 'Describe the problem',
                label: 'Description of the problem',
                placeholder: '',
            },
            repairTypeStep: {
                title: 'Type of Repair',
                invisible: 'Invisible',
                visible: 'Visible',
                funky: 'Funky',
            },
            diyStep: {
                title: 'DIY Guides',
                nothingFoundSubtitle: 'Sadly, we could not find a matching repair guide.',
                guideBy_one: 'Guide by:',
                guideBy_other: 'Guides by:',
                checkoutGuideAction: 'Checkout Guide',
                notMatching: 'No suitable guide included?',
                checkoutRepairOfferAction: 'Let it be repaired instead.',
            },
            contactStep: {
                form: {
                    title: 'Enter your details',
                    nameLabel: 'Name',
                    firstnameLabel: 'Firstname',
                    lastnameLabel: 'Lastname',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Phone',
                    maxRepairCostLabel: 'Maximum repair costs',
                    addressLabel: 'Address',
                    zipCodeLabel: 'Zip Code',
                    cityLabel: 'City',
                    countryLabel: 'Country',
                    commentLabel: 'Comment',
                    purchaseLocationLabel: 'Purchase Location',
                    purchaseDateLabel: 'Purchase Date',
                    orderReferenceLabel: 'Order Reference',
                    acceptTermsLabel: 'I agree to a repair up to the specified price limit',
                    addMoreDefects: 'Add more requests',
                    submit: 'Request Repair Offer',
                },
            },
        },
        mobilePhotoUpload: {
            retakeAction: 'Foto erneut aufnehmen',
        },
        ufixit: {
            intro: {
                toolsNeededLink: 'Tools needed for this Guide',
                toolsNeededTitle: 'Tools needed',
                sparePartsNeededLink: 'Spare Parts needed for this Guide',
                sparePartsNeededTitle: 'Spare Parts needed',
                startAction: 'Start Repairing',
            },
            common: {
                backToRequest: 'Back to your Request',
                step: 'Step',
            },
            outro: {
                enjoy: 'Enjoy your newly repaired product!',
                didYouManage: 'Did you manage to repair your item?',
                furtherAssistance: 'No, I need further assistance',
                yesIAmHappy: 'Yes, I am happy!',
                findNearestStore: 'Find your nearest store',
            },
        },
    },
    analysator: {
        keyfacts: {
            pageViews: 'Page Views',
            uniqueVisitors: 'Unique Visitors',
            submissions: 'Submissions',
        },
    },
}

export default en
