import defaultRepairGuides from '../repair-guides'
import defaultRepairServices from '../repair-services'
import zenitramProductCategoryTree from '../products/zenitram-products'
import { alpha } from '@mui/material'
import { WhitelabelStatus, type Whitelabel } from './types'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const zenitramWhitelabel: Whitelabel = {
    id: '4viuer',
    status: WhitelabelStatus.ExternalDemoAccess,
    name: 'Zenitram',
    theme: {
        palette: {
            primary: {
                main: '#00A553',
                contrastText: '#000000',
            },
            secondary: {
                main: '#E2D4C8',
                contrastText: '#282727',
            },
            getCrosshairColors: () => ({ stroke: '#00A553', fill: '#000000' }),
            getIllustrationColors: () => ({
                primary: '#00A553',
                accent: alpha('#00A553', 0.5),
            }),
            getProgressbarColor: () => '#00A553',
            getTextColor: () => '#000000',
        },
    },
    logo: 'https://ucarecdn.com/faa9ee51-eaf7-44f7-80b5-d498cb2435cf/',
    repairGuides: defaultRepairGuides,
    repairServices: defaultRepairServices,
    productCategoryTree: zenitramProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Phone',
                            label: 'serviceDesk.shared.contactStep.form.phoneLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'MaxRepairCost',
                            label: 'serviceDesk.shared.contactStep.form.maxRepairCostLabel',
                            options: { required: true },
                            fieldProps: { type: 'number' },
                        },
                    ],
                ],
            },
            activateCostEstimation: false,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle2',
                links: [
                    {
                        label: 'poc.support.repairOutroStep.locationAction',
                        href: 'https://maps.app.goo.gl/xTPxhvH59A67JAhPA',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'zenitramsails@gmail.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default zenitramWhitelabel
