import defaultRepairGuides from '../repair-guides'
import snowdoniaRepairServices from '../repair-services/snowdonia-support-services'
import snowdoniaProductCategoryTree from '../products/snowdonia-products'
import { alpha } from '@mui/material'
import { WhitelabelStatus, type Whitelabel } from './types'

/*
    TODO:
    - Adjust ID
    - Adjust name (variable and brand name)
    - Adjust theme
    - Adjust logo
    - Adjust repair guides DE / EN
    - Adjust repair services DE / EN
    - Define product category tree
    - Define outro links
    - Adjust plugin config (email recipients)
*/
const snowdoniaWhitelabel: Whitelabel = {
    id: '5skaz1',
    status: WhitelabelStatus.InternalOnly,
    name: 'Snowdonia',
    theme: {
        palette: {
            primary: {
                main: '#DF7C6E',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#E2D4C8',
                contrastText: '#282727',
            },
            getCrosshairColors: () => ({ stroke: '#DF7C6E', fill: '#ffffff' }),
            getIllustrationColors: () => ({
                primary: '#DF7C6E',
                accent: alpha('#DF7C6E', 0.5),
            }),
            getProgressbarColor: () => '#DF7C6E',
            getTextColor: () => '#000000',
        },
    },
    logo: 'https://ucarecdn.com/a1ffe8cd-d6b7-4799-9ff8-ad1d2706a9df/',
    repairGuides: defaultRepairGuides,
    repairServices: snowdoniaRepairServices,
    productCategoryTree: snowdoniaProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            options: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: false,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'rosanna@snowdoniagearrepair.co.uk',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default snowdoniaWhitelabel
